import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import firebase, { auth } from '../util/firebase'
import AuthContainer from '../containers/authContainer'

const SignIn = () => {
  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID, firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  }

  return (
    <AuthContainer>
      <div className="flex">
        <div className="w-3/4 mx-auto">
          <h1 className="mb-6">Get started</h1>
          <p>Select an option below to create an account</p>
          <p>or sign in</p>
          <StyledFirebaseAuth className="mt-10" uiConfig={uiConfig} firebaseAuth={auth} />
          <p className="mt-10">
            By continuing, you are agreeing to TGD's
            <br />
            <a className="underline" href="https://tgd.global/legal/terms-of-use" alt="Terms Of Use" target="_New">
              Terms Of Use
            </a>{' '}
            and{' '}
            <a className="underline" href="https://tgd.global/legal/privacy-policy" alt="Privacy Policy" target="_New">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </AuthContainer>
  )
}

export default SignIn
