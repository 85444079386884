import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import { auth, signOut } from '../util/firebase'
import Loader from '../components/loader'
import getDisplayName from '../util/getDisplayName'
import SignIn from '../pages/signIn'

const withAuth = (Component) => {
  Component.displayName = `withAuth(${getDisplayName(Component)})`

  function AuthWrapper(props) {
    const [authUser, authLoading, authError] = useAuthState(auth)

    const authProps = { signOut, authUser, authLoading, authError }

    if (authError) {
      return (
        <div>
          <p>authError: {authError.stack || authError}</p>
        </div>
      )
    }

    if (authLoading) {
      return <Loader />
    }

    if (authUser) {
      return <Component {...props} {...authProps} />
    }

    return <SignIn />
  }

  return AuthWrapper
}

export default withAuth
