import * as Yup from 'yup'

export const getValidationSchema = (schema) =>
  schema.reduce((accum, page) => {
    const questions = page.reduce((accumQuestions, question) => {
      const { id, type, required } = question

      if (type === 'inputText') {
        let questionValidation = Yup.string()

        if (required) {
          questionValidation = questionValidation.required('This field is required')
        }

        accumQuestions[id] = questionValidation
      } else if (type === 'radiogroup') {
        let questionValidation = Yup.object().nullable()

        if (required) {
          questionValidation = questionValidation.test(
            'required',
            'This field is required',
            (option) => !!(option && option.value)
          )
        }
        accumQuestions[id] = questionValidation
      }

      return accumQuestions
    }, {})

    accum.push(Yup.object(questions))
    return accum
  }, [])

export const getInitialValues = (schema) =>
  schema.reduce((accum, page) => {
    const questions = page.reduce((accumQuestions, question) => {
      const { id, type } = question

      if (type === 'inputText') {
        accumQuestions[id] = ''
      } else if (type === 'radiogroup') {
        accumQuestions[id] = null
      }
      return accumQuestions
    }, {})
    return {
      ...accum,
      ...questions,
    }
  }, {})
