import React, { useMemo } from 'react'
import Section from './Section'
import { removeDuplicates, listToString } from '../../util/listOperations'
import Tooltip from '../Tooltip'
import Footer from '../Footer'

const getSections = (
  { businessConsiderations, customers, partners },
  wording,
  activeTab,
  setActiveTab,
  activeQuestion,
  setActiveQuestion
) => {
  const keyPartners = partners?.length > 0 ? partners.map((partner) => partner.name) : []
  const keyActivities = removeDuplicates(businessConsiderations?.importantActivities)
  const keyResources = removeDuplicates([
    ...(businessConsiderations?.valuePropFinancial || []),
    ...(businessConsiderations?.valuePropHR || []),
    ...(businessConsiderations?.valuePropIP || []),
    ...(businessConsiderations?.valuePropPhysical || []),
    ...(businessConsiderations?.valuePropTech || []),
  ])

  const valuePropositions = customers.map((customer) => (
    <div className="pb-4">
      <span className="font-bold">Our </span> {listToString(removeDuplicates(customer?.solutionDescription || []))}{' '}
      <span className="font-bold">help(s)</span> {customer?.name} <span className="font-bold">who want to </span>
      {listToString(removeDuplicates(customer?.importantThings || []))} <span className="font-bold">by </span>{' '}
      {customer.by} {listToString(removeDuplicates(customer?.pains || []))} <span className="font-bold">and </span>{' '}
      {customer.and} {listToString(removeDuplicates(customer?.valueGained || []))}{' '}
      {customer.unlike && (
        <>
          <span className="font-bold">(unlike </span> {customer.unlike} <span className="font-bold"> )</span>
        </>
      )}
    </div>
  ))

  const customerRelationShips = removeDuplicates(
    customers.reduce((accum, customer) => (customer?.maintain?.length > 0 ? [...accum, ...customer.maintain] : []), [])
  )
  const channels = removeDuplicates(customers.reduce((accum, customer) => [...accum, ...customer.reach], []))
  const customerSegments = customers?.length > 0 ? customers.map((customer) => customer.name) : []
  const costStructure = removeDuplicates([
    ...(businessConsiderations?.expensiveActivities || []),
    ...(businessConsiderations?.expensiveResources || []),
  ])

  const revenueStreams = removeDuplicates(customers.reduce((accum, customer) => [...accum, ...customer.benefits], []))

  return [
    {
      id: 1,
      title: 'Key Partners',
      icon: <i className="fas fa-link text-xl" />,
      items: keyPartners,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 1,
      relatedRef: 'partners-tabs',
    },
    {
      id: 2,
      title: 'Key Activities',
      icon: <i className="fas fa-check-circle text-xl" />,
      items: keyActivities,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 3,
      relatedRef: 'key-activities',
    },
    {
      id: 3,
      title: 'Key Resources',
      icon: <i className="fas fa-industry text-xl" />,
      items: keyResources,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 3,
      relatedRef: 'key-resources',
    },
    {
      id: 4,
      title: 'Value Proposition',
      icon: <i className="fas fa-gift text-xl" />,
      items: valuePropositions,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 4,
    },
    {
      id: 5,
      title: `${wording.model_section4}`,
      icon: <i className="fas fa-heart text-xl" />,
      items: customerRelationShips,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 0,
      relatedRef: 'beneficiaries-q9a-0',
    },
    {
      id: 6,
      title: `${wording.model_section3}`,
      icon: <i className="fas fa-truck text-xl" />,
      items: channels,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 0,
      relatedRef: 'beneficiaries-q8a-0',
    },
    {
      id: 7,
      title: `${wording.model_section2}`,
      icon: <i className="fas fa-smile-wink text-xl" />,
      items: customerSegments,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 0,
      relatedRef: 'beneficiaries-tabs',
    },
    {
      id: 8,
      title: `${wording.model_section9}`,
      icon: <i className="fas fa-tags text-xl" />,
      items: costStructure,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 3,
      relatedRef: 'mission-cost',
    },
    {
      id: 9,
      title: `${wording.model_section5}`,
      icon: <i className="fas fa-money-bill text-xl" />,
      items: revenueStreams,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      activeQuestion: activeQuestion,
      setActiveQuestion: setActiveQuestion,
      relatedTab: 0,
      relatedRef: 'beneficiaries-q11-0',
    },
  ]
}

const ProjectCanvas = ({
  values,
  wording,
  activeTab,
  setActiveTab,
  activeQuestion,
  setActiveQuestion,
  ExecuteScroll,
}) => {
  const sections = useMemo(
    () => getSections(values, wording, activeTab, setActiveTab, activeQuestion, setActiveQuestion, ExecuteScroll),
    [values]
  )
  return (
    <>
      <div className="relative">
        <Tooltip
          moreTitle="Strategyzer Business Model Canvas"
          more="https://www.strategyzer.com/canvas/business-model-canvas"
        >
          A business model describes the rationale of how an organisation creates, delivers, and captures value, in
          economic, social, cultural, or other contexts. In this context, a business model can be considered to be a
          strategy for turning an idea into a product, service, or policy, and the plan to take that and get it into the
          hands of users.
        </Tooltip>
        <div className="project-canvas p-10 pr-20 pb-0 text-textDark">
          {sections?.length > 0 &&
            sections.map(({ id, ...props }) => <Section className={`box-${id}`} key={id} {...props} />)}
        </div>
        <Footer divider="-">
          Business Model Canvas created by{' '}
          <a className="underline" href="http://Strategyzer.com" alt="Strategyzer.com" target="_New">
            Strategyzer.com
          </a>
        </Footer>
      </div>
    </>
  )
}

export default ProjectCanvas
