const ProjectWording = (projectType) => {
  const wording =
    projectType === 'researcher'
      ? {
          cTitle: 'Beneficiaries',
          bTitle: 'Impact',
          cType: 'beneficiary',
          cTypeUpper: 'Beneficiary',
          csType: 'beneficiaries',
          csTypeUpper: 'Beneficiaries',
          competitors_Q11: 'impact',
          partners_q4: 'impact',
          model_section2: 'Beneficiaries',
          model_section3: 'Deployments',
          model_section4: 'Buy in / Support',
          model_section5: 'Mission Achievement (Impact)',
          model_section9: 'Mission Cost (Budget)',
        }
      : {
          cTitle: 'Customers',
          bTitle: 'Business',
          cType: 'customer',
          cTypeUpper: 'Customer',
          csType: 'customers',
          csTypeUpper: 'Customers',
          competitors_Q11: 'these solutions to market',
          partners_q4: 'a better product / service to market',
          model_section2: 'Customer Segments',
          model_section3: 'Channels',
          model_section4: 'Customer Relationships',
          model_section5: 'Revenue Streams',
          model_section9: 'Cost Structure',
        }

  return wording
}

export default ProjectWording
