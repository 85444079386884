import React, { memo, forwardRef } from 'react'
import Field from '../Field'

const InputField = forwardRef(
  ({
    id,
    value,
    label,
    errorMessage,
    onChange,
    onBlur,
    placeholder = '',
    disabled,
    type = 'text',
    required,
    inline,
    forwardedRef,
    ...props
  }) => (
    <Field labelClass="mb-4 font-bold" label={label} inline={inline} errorMessage={errorMessage} required={required}>
      <input
        className="appearance-none w-full text-base bold text-textDark border-b p-2 leading-tight focus:outline-none focus:shadow-outline"
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        ref={forwardedRef}
        {...props}
      />
    </Field>
  )
)

export default memo(InputField)
