import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

let firebaseConfig
if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyBGCzw9D6JcpbImzFgB_QN6o1c45UBe3HI',
    authDomain: 'ecosystem-mapper.firebaseapp.com',
    databaseURL: 'https://ecosystem-mapper.firebaseio.com',
    projectId: 'ecosystem-mapper',
    storageBucket: 'ecosystem-mapper.appspot.com',
    messagingSenderId: '893554400539',
    appId: '1:893554400539:web:79a93365635399d9a107be',
    measurementId: 'G-JPN26YBWCB',
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyAGBHlWrSnUUq1dk3XZTTOCMoUzmgEFg8g',
    authDomain: 'asymmetric-innovation-internal.firebaseapp.com',
    databaseURL: 'https://asymmetric-innovation-internal.firebaseio.com',
    projectId: 'asymmetric-innovation-internal',
    storageBucket: 'asymmetric-innovation-internal.appspot.com',
    messagingSenderId: '284261896634',
    appId: '1:284261896634:web:876c52ec8187e581b04d83',
    measurementId: 'G-17XZG1PJEJ',
  }
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

if (window.location.hostname === 'localhost') {
  console.log('localhost detected -- hitting local functions and firestore emulators')

  firebase.firestore().settings({
    host: 'localhost:8080',
    ssl: false,
  })

  firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

export const db = firebase.firestore()
export const auth = firebase.auth()
export const FieldValue = firebase.firestore.FieldValue
export default firebase

export const signOut = async () => {
  return firebase.auth().signOut()
}
