import React from 'react'

const Loader = () => (
  <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
    <span className="top-1/2 my-0 mx-auto block relative w-0 h-0" style={{ left: '-100px', marginTop: '-100px' }}>
      <svg className="fas fa-spin" width="200" height="200">
        <linearGradient id="linearColors" x1="0" y1="0" x2="4" y2="4">
          <stop stopColor="#02C7F0"></stop>
          <stop offset="50%" stopColor="#2C4CE0"></stop>
        </linearGradient>
        <circle
          r="70"
          cx="100"
          cy="100"
          className="external-circle"
          strokeWidth="18"
          fill="none"
          stroke="url(#linearColors)"
        ></circle>
      </svg>
    </span>
  </div>
)

export default Loader
