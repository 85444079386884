import React, { useState, useEffect, createRef, useRef } from 'react'
import { Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import { get, filter, isEmpty } from 'lodash'
import { minusButtonClasses, plusButtonClasses, activePillClasses, inactivePillClasses } from './questions'
import Tooltip from './Tooltip'
import Footer from './Footer'

export const defaultCustomerValues = {
  name: '',
  sector: '',
  quantity: '',
  importantThings: [''],
  pains: [''],
  valueGained: [''],
  valueEvidence: '',
  valueEvidences: [''],
  solutions: [''],
  alleviate: [''],
  gains: [''],
  solutionDescription: [''],
  reach: [''],
  reachEvidence: '',
  reachEvidences: [''],
  maintain: [''],
  maintainEvidence: '',
  valueProps: [''],
  benefits: [''],

  // These properties are just editable through ValuePropositionSentence
  by: '',
  and: '',
  unlike: '',
}

Yup.addMethod(Yup.array, 'unique', function (path, message) {
  return this.test('unique', '', function (list) {
    const errors = []
    list.forEach((item, index) => {
      const nameValue = get(item, path)

      if (nameValue && filter(list, [path, nameValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${path}`,
            message,
          })
        )
      }
    })

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors)
    }

    return true
  })
})

export const customersValidation = Yup.array(
  Yup.object({
    name: Yup.string().required('Required'),
    importantThings: Yup.array(Yup.string()),
    pains: Yup.array(Yup.string()),
    valueGained: Yup.array(Yup.string()),
    valueEvidences: Yup.array(Yup.string()),
    solutions: Yup.array(Yup.string()),
    alleviate: Yup.array(Yup.string()),
    gains: Yup.array(Yup.string()),
    maintain: Yup.array(Yup.string()),
    reach: Yup.array(Yup.string()),
    reachEvidences: Yup.array(Yup.string()),
    valueProps: Yup.array(Yup.string()),
    benefits: Yup.array(Yup.string()),

    // These properties are just editable through ValuePropositionSentence
    by: Yup.string(),
    and: Yup.string(),
    unlike: Yup.string(),
  })
).unique('name', 'Name must be unique')

const Customers = ({
  values,
  wording,
  touched,
  dirty,
  isSubmitting,
  isValid,
  errors,
  activeQuestion,
  setActiveQuestion,
}) => {
  const Customers = values.customers || { ...defaultCustomerValues }

  const checkActiveQuestion = activeQuestion?.lastIndexOf('-')
  const newActiveCustomerString = activeQuestion?.substring(checkActiveQuestion + 1)
  const newActiveCustomerTab = parseInt(newActiveCustomerString, 10)

  const [activeCustomerTab, setActiveCustomerTab] = useState(newActiveCustomerTab || 0)

  const refNames = ['beneficiaries-tabs']

  Customers &&
    Customers.length > 0 &&
    Customers.map((customer, customerIndex) =>
      refNames.push(
        'beneficiaries-q4a-' + customerIndex,
        'beneficiaries-q4b-' + customerIndex,
        'beneficiaries-q4c-' + customerIndex,
        'beneficiaries-q6a-' + customerIndex,
        'beneficiaries-q6b-' + customerIndex,
        'beneficiaries-q7-' + customerIndex,
        'beneficiaries-q8a-' + customerIndex,
        'beneficiaries-q9a-' + customerIndex,
        'beneficiaries-q11-' + customerIndex
      )
    )

  const refs = useRef([])

  refs.current = refNames.map((ref, index) => (refs.current[index] = createRef()))

  const getIndex = (refName) => {
    return refNames.indexOf(refName)
  }

  useEffect(() => {
    if (activeQuestion != null) {
      const aqIndex = getIndex(activeQuestion)
      const refCurrent = refs.current[aqIndex].current
      refCurrent.className = refCurrent.className + ' border border-secondary rounded bg-blue-100 py-3 px-2 mb-3'

      refCurrent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      setActiveQuestion(null)
    }
  }, [])

  return (
    <>
      <div className="border-l border-b border-r border-secondary px-4 relative">
        <Tooltip>
          Customers are the community of consumers, end users, or businesses that you are aiming to sell (or have use)
          your product, policy, or service. Knowing who your customers are is one of the most important building blocks
          of your business or mission model strategy, so having a deep understanding of them is very important.
        </Tooltip>
        <FieldArray
          name="customers"
          render={(arrayHelpers) => (
            <div className="pt-3">
              <ul className="flex flex-wrap pt-3" ref={refs.current[0]}>
                {Customers &&
                  Customers.length > 0 &&
                  Customers.map((customer, customerIndex) => (
                    <li
                      key={`customer-tab-${customerIndex}`}
                      className={`mr-4 ${activeCustomerTab === customerIndex ? '-mb-px' : ''}`}
                    >
                      <div
                        onClick={() => setActiveCustomerTab(customerIndex)}
                        className={activeCustomerTab === customerIndex ? activePillClasses : inactivePillClasses}
                      >
                        <span>{Customers[customerIndex].name || `${wording.cTypeUpper} ${customerIndex + 1}`}</span>

                        {touched.customers &&
                          touched.customers[customerIndex] &&
                          Object.keys(touched.customers[customerIndex]).length > 0 &&
                          errors.customers &&
                          errors.customers[customerIndex] &&
                          Object.keys(errors.customers[customerIndex]).length > 0 && (
                            <span className="ml-1 pt-1 text-red-500 text-md italic leading-none">*</span>
                          )}

                        {activeCustomerTab === customerIndex && Customers.length > 1 && (
                          <a
                            className="ml-4"
                            onClick={() => {
                              const confirm = window.confirm(
                                'Are you sure you want to delete this tab? All of its content will be lost if you proceed.'
                              )
                              if (confirm == true) {
                                arrayHelpers.remove(customerIndex)
                                setActiveCustomerTab(0)
                              }
                            }}
                            title="Remove"
                          >
                            <i className="fas fa-trash text-red-300" />
                          </a>
                        )}
                      </div>
                    </li>
                  ))}

                <li className={`mr-1`}>
                  <button
                    className={plusButtonClasses}
                    type="button"
                    disabled={dirty || isSubmitting || !isValid}
                    onClick={() => {
                      arrayHelpers.push({ ...defaultCustomerValues })
                      setActiveCustomerTab(Customers.length)
                    }}
                  >
                    +
                  </button>
                </li>
              </ul>

              <p className="text-xs italic">
                {dirty || isSubmitting || !isValid ? (
                  <>
                    Please <button className="underline text-xs italic">save changes</button> to add new {wording.cType}{' '}
                    tab. A nonduplicate Name is required.
                  </>
                ) : (
                  ''
                )}
              </p>

              {Customers &&
                Customers.length > 0 &&
                Customers.map((customer, customerIndex) => {
                  const currentRefIndex = (customerIndex + 1) * 9 - 9
                  return (
                    <div
                      key={`customer-${customerIndex}`}
                      className={`${customerIndex === activeCustomerTab ? '' : 'hidden'}`}
                    >
                      <div className="flex flex-wrap w-full mt-6">
                        <div className="mb-4 w-full mr-32">
                          <p className="block text-sm font-medium mb-2">1. Name</p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`customers.${customerIndex}.name`}
                            placeholder={`${wording.cTypeUpper} name`}
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`customers.${customerIndex}.name`}
                            component="div"
                          />
                        </div>

                        <div className="mb-4 w-full mr-32">
                          <div className="mb-4">
                            <p className="block text-sm font-medium mb-2">
                              2. What sector does this stakeholder operate in?
                            </p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`customers.${customerIndex}.sector`}
                              placeholder="Sector"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`customers.${customerIndex}.sector`}
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="mb-4 w-full mr-32">
                          <div className="mb-4">
                            <p className="block text-sm font-medium mb-2">
                              3. How many of these {wording.csType} do you think would use your product or service?
                            </p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                              type="number"
                              name={`customers.${customerIndex}.quantity`}
                              placeholder="Quantity"
                            />
                          </div>
                        </div>

                        <div className="mb-4 w-full mr-32">
                          <p className="block text-sm font-medium mb-2">4.</p>
                          <div className="ml-4">
                            <div className="mb-4" ref={refs.current[currentRefIndex + 1]}>
                              <p className="block text-sm font-medium mb-2">
                                a. What are the important things your {wording.csType} are trying to do in their work or
                                in their lives?
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.importantThings`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].importantThings &&
                                      Customers[customerIndex].importantThings.length > 0 &&
                                      Customers[customerIndex].importantThings.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.importantThings.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>

                            <div className="mb-4" ref={refs.current[currentRefIndex + 2]}>
                              <p className="block text-sm font-medium mb-2">
                                b. What are the pains they experience when trying to do these things?
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.pains`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].pains &&
                                      Customers[customerIndex].pains.length > 0 &&
                                      Customers[customerIndex].pains.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.pains.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>

                            <div className="mb-4" ref={refs.current[currentRefIndex + 3]}>
                              <p className="block text-sm font-medium mb-2">
                                c. What is the value they hope to gain when they achieve these things?
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.valueGained`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].valueGained &&
                                      Customers[customerIndex].valueGained.length > 0 &&
                                      Customers[customerIndex].valueGained.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.valueGained.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>

                            <div className="mb-4">
                              <p className="block text-sm font-medium mb-2">
                                d. What evidence do you have of this? (i.e. an anecdote from an interview, data or
                                information from research, etc.)
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.valueEvidences`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].valueEvidences &&
                                      Customers[customerIndex].valueEvidences.length > 0 &&
                                      Customers[customerIndex].valueEvidences.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.valueEvidences.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-6 w-full mr-32">
                          <p className="block text-sm font-medium mb-2">
                            5. Have they tried solutions before? If yes, what were they?
                          </p>
                          <FieldArray
                            name={`customers.${customerIndex}.solutions`}
                            render={(arrayHelpers) => (
                              <div>
                                {Customers[customerIndex].solutions &&
                                  Customers[customerIndex].solutions.length > 0 &&
                                  Customers[customerIndex].solutions.map((x, index) => (
                                    <div key={index} className="flex items-start">
                                      <Field
                                        className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                        type="text"
                                        placeholder="Answer"
                                        name={`customers.${customerIndex}.solutions.${index}`}
                                      />
                                      <button
                                        className={minusButtonClasses}
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        -
                                      </button>
                                    </div>
                                  ))}
                                <button
                                  className={plusButtonClasses}
                                  type="button"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  +
                                </button>
                              </div>
                            )}
                          />
                        </div>

                        <div className="mb-4 w-full mr-32">
                          <p className="block text-sm font-medium mb-2">6.</p>
                          <div className="ml-4">
                            <div className="mb-4" ref={refs.current[currentRefIndex + 4]}>
                              <p className="block text-sm font-medium mb-2">
                                a. How does your solution alleviate this {wording.cType}'s pains and challenges?
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.alleviate`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].alleviate &&
                                      Customers[customerIndex].alleviate.length > 0 &&
                                      Customers[customerIndex].alleviate.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.alleviate.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>

                            <div className="mb-4" ref={refs.current[currentRefIndex + 5]}>
                              <p className="block text-sm font-medium mb-2">
                                b. How does your solution make it easier to achieve their goal?
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.gains`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].gains &&
                                      Customers[customerIndex].gains.length > 0 &&
                                      Customers[customerIndex].gains.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.gains.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-6 w-full mr-32" ref={refs.current[currentRefIndex + 6]}>
                          <p className="block text-sm font-medium mb-2">
                            7. Describe your solutions to these {wording.csType}
                          </p>
                          <FieldArray
                            name={`customers.${customerIndex}.solutionDescription`}
                            render={(arrayHelpers) => (
                              <div>
                                {Customers[customerIndex].solutionDescription &&
                                  Customers[customerIndex].solutionDescription.length > 0 &&
                                  Customers[customerIndex].solutionDescription.map((x, index) => (
                                    <div key={index} className="flex items-start">
                                      <Field
                                        className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                        type="text"
                                        placeholder="Answer"
                                        name={`customers.${customerIndex}.solutionDescription.${index}`}
                                      />
                                      <button
                                        className={minusButtonClasses}
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        -
                                      </button>
                                    </div>
                                  ))}
                                <button
                                  className={plusButtonClasses}
                                  type="button"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  +
                                </button>
                              </div>
                            )}
                          />
                        </div>

                        <div className="mb-4 w-full mr-32">
                          <p className="block text-sm font-medium mb-2">8.</p>
                          <div className="ml-4">
                            <div className="mb-4" ref={refs.current[currentRefIndex + 7]}>
                              <p className="block text-sm font-medium mb-2">
                                a. How do you reach this type of {wording.cType}? (i.e. through TV ads, via social
                                media, cold calling, etc.)
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.reach`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].reach &&
                                      Customers[customerIndex].reach.length > 0 &&
                                      Customers[customerIndex].reach.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.reach.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>

                            <div className="mb-4">
                              <p className="block text-sm font-medium mb-2">
                                b. What evidence do you have that this is the best method? (i.e. an anecdote from an
                                interview, data or information from research, etc.)
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.reachEvidences`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].reachEvidences &&
                                      Customers[customerIndex].reachEvidences.length > 0 &&
                                      Customers[customerIndex].reachEvidences.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.reachEvidences.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-4 w-full mr-32">
                          <p className="block text-sm font-medium mb-2">9.</p>
                          <div className="ml-4">
                            <div className="mb-4" ref={refs.current[currentRefIndex + 8]}>
                              <p className="block text-sm font-medium mb-2">
                                a. How do you maintain a relationship with this type of {wording.cType}? (i.e. Monthly
                                marketing emails, sending free promotions, etc.)
                              </p>
                              <FieldArray
                                name={`customers.${customerIndex}.maintain`}
                                render={(arrayHelpers) => (
                                  <div>
                                    {Customers[customerIndex].maintain &&
                                      Customers[customerIndex].maintain.length > 0 &&
                                      Customers[customerIndex].maintain.map((x, index) => (
                                        <div key={index} className="flex items-start">
                                          <Field
                                            className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                            type="text"
                                            placeholder="Answer"
                                            name={`customers.${customerIndex}.maintain.${index}`}
                                          />
                                          <button
                                            className={minusButtonClasses}
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            -
                                          </button>
                                        </div>
                                      ))}
                                    <button
                                      className={plusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              />
                            </div>

                            <div className="mb-4">
                              <p className="block text-sm font-medium mb-2">
                                b. What evidence do you have that this is the best method? (i.e. an anecdote from an
                                interview, data or information from research, etc.)
                              </p>
                              <Field
                                className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name={`customers.${customerIndex}.maintainEvidence`}
                                placeholder="Answer"
                              />
                              <ErrorMessage
                                className="text-red-500 text-xs italic"
                                name={`customers.${customerIndex}.maintainEvidence`}
                                component="div"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-6 w-full mr-32">
                          <p className="block text-sm font-medium mb-2">
                            10. What is your value proposition to this type of {wording.cType}?
                          </p>
                          <FieldArray
                            name={`customers.${customerIndex}.valueProps`}
                            render={(arrayHelpers) => (
                              <div>
                                {Customers[customerIndex].valueProps &&
                                  Customers[customerIndex].valueProps.length > 0 &&
                                  Customers[customerIndex].valueProps.map((x, index) => (
                                    <div key={index} className="flex items-start">
                                      <Field
                                        className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                        type="text"
                                        placeholder="Answer"
                                        name={`customers.${customerIndex}.valueProps.${index}`}
                                      />
                                      <button
                                        className={minusButtonClasses}
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        -
                                      </button>
                                    </div>
                                  ))}
                                <button
                                  className={plusButtonClasses}
                                  type="button"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  +
                                </button>
                              </div>
                            )}
                          />
                        </div>
                        <div className="w-full" ref={refs.current[currentRefIndex + 9]}>
                          <div className="mb-6 mr-32">
                            <p className="block text-sm font-medium mb-2">
                              11. How are you benefiting financially from this type of {wording.cType}?
                            </p>
                            <FieldArray
                              name={`customers.${customerIndex}.benefits`}
                              render={(arrayHelpers) => (
                                <div>
                                  {Customers[customerIndex].benefits &&
                                    Customers[customerIndex].benefits.length > 0 &&
                                    Customers[customerIndex].benefits.map((x, index) => (
                                      <div key={index} className="flex items-start">
                                        <Field
                                          className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                          type="text"
                                          placeholder="Answer"
                                          name={`customers.${customerIndex}.benefits.${index}`}
                                        />
                                        <button
                                          className={minusButtonClasses}
                                          type="button"
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          -
                                        </button>
                                      </div>
                                    ))}
                                  <button
                                    className={plusButtonClasses}
                                    type="button"
                                    onClick={() => arrayHelpers.push('')}
                                  >
                                    +
                                  </button>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          )}
        />
      </div>
      <Footer />
    </>
  )
}

export default Customers
