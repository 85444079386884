import React from 'react'
import classnames from 'classnames'

import Modal from './Modal'

const Action = ({ title, onClick, variant }) => {
  const classes = classnames(
    'bg-transparent hover:bg-primary text-primary font-semibold hover:text-white py-2 px-4 border border-primary hover:border-transparent rounded-full mr-4'
  )

  return (
    <span className="flex w-full rounded-md sm:ml-3 sm:w-auto">
      <button onClick={onClick} type="button" className={classes}>
        {title}
      </button>
    </span>
  )
}

const Popover = ({ children, primaryAction, secondaryAction, ...props }) => (
  <Modal {...props}>
    <div className="px-4 pt-5 pb-4 sm:pb-4">
      <div className="sm:flex sm:items-start">{children}</div>
    </div>
    {(primaryAction || secondaryAction) && (
      <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        {primaryAction && <Action {...primaryAction} />}
        {secondaryAction && <Action {...secondaryAction} />}
      </div>
    )}
  </Modal>
)

export default Popover
