import React from 'react'

import ClientOnlyPortal from './ClientOnlyPortal'
import useClickOutside from '../../hooks/useClickOutside'
import useLockBodyScroll from '../../hooks/useLockBodyScroll'

const Modal = ({ onRequestClose, open, children }) => {
  const clickOutsideHandler = useClickOutside(onRequestClose)
  useLockBodyScroll(open)

  if (!open) {
    return null
  }

  return (
    <ClientOnlyPortal selector="#modal">
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* Background overlay */}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
          {/* Modal panel */}
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
            ref={clickOutsideHandler.ref}
          >
            {children}
          </div>
        </div>
      </div>
    </ClientOnlyPortal>
  )
}

export default Modal
