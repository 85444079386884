import React, { useRef, useEffect } from 'react'
import ForceGraph2D from 'react-force-graph-2d'
import { useWindowSize } from 'react-use'
import { forceCollide } from 'd3-force'
import Tooltip from './Tooltip'
import Footer from './Footer'

function saveCanvas(e, imageName) {
  // Caution: Hack!
  e.stopPropagation()
  e.preventDefault()
  const canvas = document.getElementsByTagName('canvas')[0]
  const imageData = canvas.toDataURL('image/png', 1)
  const link = document.getElementById('hiddenlink')
  link.setAttribute('download', `${imageName}.png`)
  link.setAttribute('href', imageData.replace(/^data:image\/[^;]/, 'data:image/octet-stream'))
  link.click()
}

const ForceTree = ({ data, imageName }) => {
  const fgRef = useRef()
  const { width, height } = useWindowSize()

  useEffect(() => {
    fgRef.current.d3Force('center', null)
    fgRef.current.d3Force('charge').strength(-1000)
    // fgRef.current.d3Force('link').distance(50)
    fgRef.current.d3Force('collide', forceCollide(10))
  }, [])

  return (
    <>
      <div className="relative border-l border-r border-b border-secondary relative">
        <Tooltip>
          A stakeholder map helps you visualise the key stakeholders in the ecosystem. Understanding the stakeholders,
          their motivations, and how they interact with you and others will help you position your product or service in
          a way that is differentiated and valued.
        </Tooltip>
        <ForceGraph2D
          ref={fgRef}
          graphData={data}
          dagMode={'radialout'}
          width={width - 245}
          height={height - 300}
          dagLevelDistance={130}
          cooldownTicks={100}
          onEngineStop={() => fgRef.current.zoomToFit(400)}
          backgroundColor="white"
          nodeId="path"
          nodeVal={(node) => 150 / (node.level + 1)}
          nodeLabel="leaf"
          nodeAutoColorBy="module"
          d3VelocityDecay={0.5}
          nodeCanvasObjectMode={() => 'after'}
          nodeCanvasObject={(node, ctx, globalScale) => {
            const { length, [length - 1]: label } = node.path.split('|')
            const fontSize = (35 - node.level + 1) / (globalScale + node.level + 1)
            ctx.font = `${fontSize}px Sans-Serif`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = 'black'
            ctx.fillText(label, node.x, node.y)
          }}
        />

        <button
          className="hover:bg-primary text-primary hover:text-white py-2 px-8 border border-primary rounded-full mr-2 absolute"
          style={{ top: '18px', right: '80px' }}
          onClick={(e) => saveCanvas(e, imageName)}
        >
          Save as image
        </button>
      </div>
      <Footer />
    </>
  )
}

function formValuesToTree(formValues, wording) {
  const nodePaths = [
    ...formValues.partners.flatMap((partner) => [`${formValues.projectName}|Partners|${partner.name}`]),
    ...formValues.customers.flatMap((customer) => [
      `${formValues.projectName}|${wording.csTypeUpper}|${customer.name}`,
    ]),
    ...formValues.competitors.flatMap((competitor) => [`${formValues.projectName}|Competitors|${competitor.name}`]),
  ].filter((path) => !path.startsWith('|') && !path.endsWith('|'))

  if (nodePaths.length === 0) {
    return []
  }

  return [
    formValues.projectName || 'Project name',
    `${formValues.projectName}|${wording.csTypeUpper}`,
    `${formValues.projectName}|Partners`,
    `${formValues.projectName}|Competitors`,
    ...nodePaths,
  ]
}

function DAG({ formValues, wording }) {
  const treePaths = formValuesToTree(formValues, wording)
  const nodes = []
  const links = []
  const size = 20

  if (!treePaths || !treePaths.length) {
    return (
      <div style={{ height: '400px', paddingTop: '180px' }}>
        <p className="text-center text-textLight">You don't have enough data to view the diagram yet.</p>
        <p className="text-center text-textLight">Make sure you've entered a project name.</p>
      </div>
    )
  }

  treePaths.forEach((path) => {
    const levels = path.split('|')
    const level = levels.length - 1
    const module = level > 0 ? levels[1] : null
    const leaf = levels.pop()
    const parent = levels.join('|')

    const node = {
      path,
      leaf,
      module,
      size,
      level,
    }

    nodes.push(node)

    if (parent) {
      links.push({ source: parent, target: path, targetNode: node })
    }
  })

  return (
    <div>
      <a id="hiddenlink" />
      <ForceTree data={{ nodes, links }} imageName={formValues.projectName} />
    </div>
  )
}

export default DAG
