import React, { useState } from 'react'
import { Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import { plusButtonClasses, activePillClasses, inactivePillClasses } from './questions'
import Tooltip from './Tooltip'
import Footer from './Footer'

const interviewDefaultValues = {
  interviewCode: '',
  nameParticipant: '',
  organisation: '',
  role: '',
  descriptionTest: '',
  goal: '',
  notes: '',
}
export const defaultInterviewValues = {
  customer: [{ ...interviewDefaultValues }],
  competitor: [{ ...interviewDefaultValues }],
  partner: [{ ...interviewDefaultValues }],
  other: [{ ...interviewDefaultValues }],
}

const interviewObjectSchema = Yup.object({
  interviewCode: Yup.string(),
  nameParticipant: Yup.string(),
  organisation: Yup.string(),
  role: Yup.string(),
  goal: Yup.string(),
  hypothesis: Yup.string(),
  notes: Yup.string(),
})

export const interviewsValidation = Yup.object({
  customer: Yup.array(interviewObjectSchema),
  competitor: Yup.array(interviewObjectSchema),
  partner: Yup.array(interviewObjectSchema),
  other: Yup.array(interviewObjectSchema),
})

const Interviews = ({ values, wording, touched, errors }) => {
  const interviews = values?.interviews || defaultInterviewValues
  const [activeInterviewTab, setActiveCustomerTab] = useState(0)
  const [interviewType, setInterviewType] = useState('customer')

  const activeInterviews = interviews[interviewType]

  const tabTitle = interviewType === 'customer' ? wording.cType : interviewType

  return (
    <>
      <div className="relative">
        <Tooltip moreTitle="Talkingtohumans.com" more="https://www.talkingtohumans.com/">
          Interviewing stakeholders and customers is a key method for uncovering the insights needed to deliver a
          product or service successfully into the hands of users or customers. This method is valid for both commercial
          and non-commercial applications. When undertaking an interview, remember to keep the language simple, problem
          focused, and importantly remember that the conversation is about understanding them, not selling you.
        </Tooltip>
        <div className="border-l border-b border-r border-secondary pt-4 px-4">
          <select
            className="form-select mr-16 block w-64 border border-secondary shadow"
            value={interviewType}
            onChange={(event) => {
              setInterviewType(event.target.value)
              setActiveCustomerTab(0)
            }}
          >
            <option value="customer">{wording.cTypeUpper}</option>
            <option value="competitor">Competitor</option>
            <option value="partner">Partner</option>
            <option value="other">Other</option>
          </select>

          <FieldArray
            name={`interviews[${interviewType}]`}
            render={(arrayHelpers) => (
              <div>
                <ul className="flex py-6">
                  {activeInterviews?.length > 0 &&
                    activeInterviews.map((_interview, interviewIndex) => (
                      <li
                        key={`interview-tab-${interviewType}-${interviewIndex}`}
                        className={`mr-4 ${activeInterviewTab === interviewIndex ? '-mb-px' : ''}`}
                      >
                        <div
                          onClick={() => setActiveCustomerTab(interviewIndex)}
                          className={activeInterviewTab === interviewIndex ? activePillClasses : inactivePillClasses}
                        >
                          <span>
                            {activeInterviews[interviewIndex].interviewCode ||
                              `Interview ${tabTitle} ${interviewIndex + 1}`}
                          </span>

                          {touched?.interviews &&
                            touched?.interviews[interviewIndex] &&
                            Object.keys(touched?.interviews[interviewIndex]).length > 0 &&
                            errors?.interviews &&
                            errors?.interviews[interviewIndex] &&
                            Object.keys(errors.interviews[interviewIndex]).length > 0 && (
                              <span className="ml-1 pt-1 text-red-500 text-md italic leading-none">*</span>
                            )}

                          {activeInterviewTab === interviewIndex && activeInterviews?.length > 1 && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              className="ml-4"
                              onClick={() => {
                                const confirm = window.confirm(
                                  'Are you sure you want to delete this tab? All of its content will be lost if you proceed.'
                                )
                                if (confirm == true) {
                                  arrayHelpers.remove(interviewIndex)
                                }
                              }}
                              title="Remove"
                            >
                              <i className="fas fa-trash text-red-300" />
                            </a>
                          )}
                        </div>
                      </li>
                    ))}

                  <li className={`mr-1`}>
                    <button
                      className={plusButtonClasses}
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({ ...defaultInterviewValues })
                        setActiveCustomerTab(activeInterviews.length || 0)
                      }}
                    >
                      +
                    </button>
                  </li>
                </ul>

                {activeInterviews?.length > 0 &&
                  activeInterviews.map((_interview, interviewIndex) => (
                    <div
                      key={`interview-${interviewType}-${interviewIndex}`}
                      className={classnames('flex', { hidden: interviewIndex !== activeInterviewTab })}
                    >
                      <div className="flex flex-wrap w-1/2">
                        <div className="mb-4 w-full mr-16">
                          <p className="block text-sm font-medium mb-2">Interview code or number</p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`interviews.${interviewType}.${interviewIndex}.interviewCode`}
                            placeholder="Interview code or number"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`interviews.${interviewType}.${interviewIndex}.interviewCode`}
                            component="div"
                          />
                        </div>

                        <div className="mb-4 w-full mr-16">
                          <div className="mb-4">
                            <p className="block text-sm font-medium mb-2">Name of participant</p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`interviews.${interviewType}.${interviewIndex}.nameParticipant`}
                              placeholder="Name of participant"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`interviews.${interviewType}.${interviewIndex}.nameParticipant`}
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="mb-4 w-full mr-16">
                          <div className="mb-4">
                            <p className="block text-sm font-medium mb-2">Organisation</p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`interviews.${interviewType}.${interviewIndex}.organisation`}
                              placeholder="Organisation"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`interviews.${interviewType}.${interviewIndex}.organisation`}
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="mb-4 w-full mr-16">
                          <div className="mb-4">
                            <p className="block text-sm font-medium mb-2">Role</p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`interviews.${interviewType}.${interviewIndex}.role`}
                              placeholder="Role"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`interviews.${interviewType}.${interviewIndex}.role`}
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="mb-4 w-full mr-16">
                          <div className="mb-4">
                            <p className="block text-sm font-medium mb-2">
                              What do we want test with this interviewee?
                            </p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`interviews.${interviewType}.${interviewIndex}.goal`}
                              placeholder=""
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`interviews.${interviewType}.${interviewIndex}.goal`}
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="mb-4 w-full mr-16">
                          <div className="mb-4">
                            <p className="block text-sm font-medium mb-2">
                              What is our hypothesis? What do we believe to be true?
                            </p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`interviews.${interviewType}.${interviewIndex}.hypothesis`}
                              placeholder=""
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`interviews.${interviewType}.${interviewIndex}.hypothesis`}
                              component="div"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col w-1/2">
                        <p className="block text-sm font-medium mb-2">Interview Notes</p>
                        <div className="pb-8 mx-0 h-full">
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full h-full leading-tight focus:outline-none focus:shadow-outline"
                            as="textarea"
                            rows="10"
                            cols="50"
                            name={`interviews.${interviewType}.${interviewIndex}.notes`}
                            placeholder=""
                          />
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name={`interviews.${interviewType}.${interviewIndex}.notes`}
                          component="div"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            )}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Interviews
