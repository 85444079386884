import React, { useMemo } from 'react'
import Wizard from './Wizard'
import Page from './Wizard/Page'
import { getInitialValues, getValidationSchema } from './Wizard/getFormSetup'

const QUESTIONS_SCHEMA = [
  // page 1
  [
    {
      id: 'projectType',
      label: 'Which of these elements most accurately reflects what you want to do?',
      type: 'radiogroup',
      options: [
        {
          id: 1,
          name: 'I have a research project I want to build into a massive program of work',
          value: 'I have a research project I want to build into a massive program of work',
          type: 'researcher',
        },
        {
          id: 2,
          name: 'I have a product/service I want to sell to customers',
          value: 'I have a product/service I want to sell to customers',
          type: 'business',
        },
        {
          id: 3,
          name: 'I just want to better understand my stakeholders',
          value: 'I just want to better understand my stakeholders',
          type: 'business',
        },
      ],
      required: true,
      includeOptInput: {
        placeholder: 'Please specify',
      },
    },
    {
      id: 'progress',
      label: 'How far along is your idea?',
      type: 'radiogroup',
      options: [
        {
          id: 1,
          name: 'I just started',
          value: 'I just started',
        },
        {
          id: 2,
          name: `I've thought through the ideas but haven't implemented anything`,
          value: `I've thought through the ideas but haven't implemented anything`,
        },
        {
          id: 3,
          name: `I've launched my idea into the world, but just want to grow it...`,
          value: `I've launched my idea into the world, but just want to grow it...`,
        },
      ],
      required: true,
      includeOptInput: {
        placeholder: 'Please specify',
      },
    },
  ],
  // page 2
  [
    {
      id: 'name',
      label: `What's the name of of your startup/project/company`,
      type: 'inputText',
      required: true,
    },
    {
      id: 'description',
      label: 'What is your service or solution?',
      type: 'inputText',
      required: true,
    },
    {
      id: 'sector',
      label: 'What sector do you operate in?',
      type: 'inputText',
      required: true,
    },
  ],
]

const ProjectQuestions = ({ onSubmit, onBack, error }) => {
  const schema = useMemo(() => {
    let questionIndex = 0

    return QUESTIONS_SCHEMA.reduce(
      (accumPages, page) => [
        ...accumPages,
        page.map((question) => {
          questionIndex += 1
          return { ...question, questionIndex }
        }),
      ],
      []
    )
  }, [])

  const initialValues = useMemo(() => getInitialValues(schema), [schema])
  const validationSchema = useMemo(() => getValidationSchema(schema), [schema])

  const pages = useMemo(() => schema.map((page, index) => <Page key={`page-${index}`} questions={page} />), [schema])

  return (
    <Wizard
      onSubmit={onSubmit}
      onBack={onBack}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pages={pages}
      error={error}
    />
  )
}

export default ProjectQuestions
