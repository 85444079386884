import React from 'react'
import Field from '../Field'

const Radiobox = React.memo(
  ({ id, required, label, labelClass, checked = false, onChange, errorMessage, ...props }) => {
    const stopPropagation = React.useCallback((e) => {
      e.stopPropagation()
    }, [])
    return (
      <>
        <Field
          label={label}
          labelClass={labelClass}
          errorMessage={errorMessage}
          required={required}
          onChange={onChange}
          inline
          reverse
          {...props}
        >
          <input className="form-radio h-4 w-4" type="radio" id={id} onChange={stopPropagation} checked={checked} />
        </Field>
      </>
    )
  }
)

export default Radiobox
