import React, { useState } from 'react'
import { useAsyncFn } from 'react-use'

import api from '../util/api'

import AuthContainer from '../containers/authContainer'

const Invite = ({ authUser, signOut }) => {
  // Set organisation color theme
  document.documentElement.style.setProperty('--color-primary', '#2C4CE0')
  document.documentElement.style.setProperty('--color-secondary', '#e2e8f0')

  const [orgCode, setInviteCode] = useState('')
  const [{ loading, error }, registerUser] = useAsyncFn(
    async (orgCode) => {
      return api('/registerUserWithInviteCode', {
        method: 'POST',
        headers: { Authorization: await authUser.getIdToken() },
        body: JSON.stringify({ orgCode }),
      })
    },
    [authUser]
  )

  return (
    <AuthContainer>
      <div className="flex">
        <div className="w-3/4 mx-auto">
          <h1 className="mb-6">Your Organisation Invite Code</h1>
          <p>Thanks for signing up!</p>
          <p>Enter you organisation invite code below.</p>

          <div className="mt-8">
            <input
              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="orgCode"
              type="text"
              placeholder="Organisation code"
              value={orgCode}
              onChange={(e) => setInviteCode(e.target.value)}
            />
            {error && <p className="text-red-500 text-xs italic mt-2">{error.message}</p>}

            <p className="text-textLight text-xs mt-3">
              Don’t have an organisation invite code?{' '}
              <a className="text-textLight underline" href="https://tgd.global/connect">
                Contact us
              </a>
              .
            </p>

            <div className="mt-12 w-full text-right">
              <button
                className="hover:bg-primary text-primary hover:text-white py-2 px-8 border border-primary rounded-full mr-2"
                disabled={loading}
                onClick={signOut}
              >
                Cancel
              </button>
              <button
                className="bg-primary hover:bg-white text-white hover:text-primary py-2 px-8 border border-primary rounded-full"
                disabled={loading}
                onClick={() => registerUser(orgCode)}
              >
                Accept Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </AuthContainer>
  )
}

export default Invite
