import React, { useState } from 'react'

const Tooltip = ({ children, more, moreTitle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleListener = (listen) => {
    listen
      ? document.addEventListener('click', onClickOutsideListener)
      : document.removeEventListener('click', onClickOutsideListener)
  }

  const onClickOutsideListener = () => {
    setIsOpen(false)
    toggleListener(false)
  }

  const title = moreTitle ? moreTitle : 'Read more'

  return (
    <>
      <div className="absolute z-10 right-0 mr-8 mt-6 text-primary">
        <button
          type="button"
          className="focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
          onMouseLeave={() => {
            isOpen && toggleListener(true)
          }}
        >
          <svg
            height="22px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 96.6 96.6"
            fill="currentColor"
          >
            <path
              d="M48.3,96.6C21.6,96.6,0,74.9,0,48.3S21.6,0,48.3,0s48.3,21.7,48.3,48.3S74.9,96.6,48.3,96.6z M48.3,5.9
	        C24.9,5.9,6,24.9,6,48.3c0,23.3,19,42.3,42.3,42.3s42.3-19,42.3-42.3C90.6,24.9,71.6,5.9,48.3,5.9z"
            />
            <path
              d="M48.8,22.6c-9.1,0-16.6,7.4-16.6,16.6c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7c0-6.2,5-11.2,11.2-11.2S60,33,60,39.2
          s-5,11.2-11.2,11.2c-1.5,0-2.7,1.2-2.7,2.7v8.1c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7v-5.7c7.9-1.3,13.9-8.1,13.9-16.4
          C65.4,30,58,22.6,48.8,22.6z"
            />
            <circle cx="48.8" cy="72.1" r="3.8" />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          className="absolute z-10 right-0 mr-16 mt-6 pl-10 pr-6 py-6 w-128 text-sm bg-white border border-secondary rounded shadow"
          onMouseEnter={() => {
            toggleListener(false)
          }}
          onMouseLeave={() => {
            toggleListener(true)
          }}
        >
          <button
            type="button"
            className="far fa-times-circle absolute top-0 left-0 mt-1 ml-1 focus:outline-none text-gray-400 hover:text-primary"
            onClick={() => setIsOpen(false)}
          />

          <div>{children}</div>
          {more && (
            <div className="mt-4">
              <a className="text-primary underline" href={more} alt={title} target="_New">
                {title}
              </a>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Tooltip
