import React from 'react'
import { auth } from '../util/firebase'

function Nav({ signOut, userData, orgData }) {
  return (
    <nav className="flex items-center justify-between flex-wrap shadow border-0 border-b border-secondary w-full">
      <div className="h-20 w-2/3 flex">
        {!orgData.tgd_logo_off && <img width="138" src="/images/TGDLogo.png" alt="TGD Logo" />}
        {orgData.logo_1 && <img className="pl-4 mt-4 mb-4" src={orgData.logo_1} alt="Logo" />}
        {orgData.logo_2 && <img className="pl-4 mt-4 mb-4" src={orgData.logo_2} alt="Logo" />}
      </div>
      <div
        className=" flex justify-center items-center justify-end mr-4"
        style={{
          height: '50px',
          minWidth: '280px',
          borderRadius: '25px 5px 5px 25px',
          backgroundColor: '#F0F1F3',
        }}
      >
        <div className="flex items-center" style={{ height: '50px' }}>
          {userData.org === 'CSIRO' ? (
            <img
              style={{
                height: '58px',
                width: '58px',
                borderRadius: '50%',
                color: 'white',
                border: '4px solid #FFFFFF',
                flex: '0 0 58px',
              }}
              src="/images/csiro-logo.svg"
              alt="CSIRO"
            />
          ) : (
            <div
              className="flex justify-center items-center bg-primary"
              style={{
                height: '58px',
                width: '58px',
                borderRadius: '50%',
                color: 'white',
                border: '4px solid #FFFFFF',
                flex: '0 0 58px',
              }}
            >
              <h2>{auth.currentUser.displayName.substr(0, 1)}</h2>
            </div>
          )}
        </div>
        <div
          className="ml-2 pr-4"
          style={{
            color: '#283E5F',
            fontFamily: 'Roboto',
          }}
        >
          <p
            style={{
              height: '19px',
              fontSize: '16px',
              fontWeight: '500',
              letterSpacing: '-0.39px',
              lineHeight: '19px',
            }}
          >
            {auth.currentUser.displayName}
          </p>
          <p
            style={{
              height: '16px',
              fontSize: '14px',
              letterSpacing: '-0.34px',
              lineHeight: '16px',
            }}
          >
            {auth.currentUser.email}
          </p>
        </div>

        <div className="ml-auto mr-2 cursor-pointer" onClick={signOut} title="Sign out">
          <i className="fas fa-sign-out-alt text-2xl text-textLight" />
        </div>
      </div>
    </nav>
  )
}

export default Nav
