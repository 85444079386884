import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { useAsyncFn } from 'react-use'
import * as Yup from 'yup'
import api from '../util/api'
import { db, FieldValue } from '../util/firebase'
import Footer from './Footer'

const Schema = Yup.object().shape({
  email: Yup.string().required().email(),
})

const Collaborators = ({ submissionId, authUser }) => {
  const [invitees, addInvitee] = useState([])
  const [{ loading, error }, addCollaborator] = useAsyncFn(
    async (email) => {
      return api('/addCollaborator', {
        method: 'POST',
        headers: { Authorization: await authUser.getIdToken() },
        body: JSON.stringify({ submissionId, email }),
      })
    },
    [authUser]
  )

  useEffect(() => {
    async function getMember() {
      const { members } = await api(`/getCollaborators?submissionId=${submissionId}`, {
        method: 'GET',
        headers: { Authorization: await authUser.getIdToken() },
      })
      addInvitee(members)
    }
    if (submissionId) {
      getMember()
    }
  }, [submissionId, authUser])

  const onSubmit = async (values, { resetForm }) => {
    const newEmail = values.email.toLowerCase()
    if (invitees.every((invitee) => invitee.email !== newEmail)) {
      try {
        const newCollaborator = await addCollaborator(newEmail)
        const { email, uid } = newCollaborator

        if (email && uid) {
          addInvitee([...invitees, { email, uid }])
        }
      } catch (err) {
        console.log(err)
      }
    }
    resetForm()
  }

  const onRemoveInvitee = async (inviteeId) => {
    try {
      await db
        .collection('submissions')
        .doc(submissionId)
        .update({
          members: FieldValue.arrayRemove(db.collection('users').doc(inviteeId)),
        })
      addInvitee(invitees.filter((invitee) => invitee.uid !== inviteeId))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <div className="border-l border-b border-r border-secondary px-4">
        <div className="mb-4 py-6 ">
          <Formik initialValues={{ email: '' }} validationSchema={Schema} onSubmit={onSubmit} enableReinitialize>
            {({ touched, errors }) => (
              <Form className="flex w-full">
                <div className="w-1/4 pr-2">
                  <Field
                    className="bg-white focus:outline-none focus:shadow-outline border border-secondary py-2 px-4 block w-full appearance-none leading-normal"
                    name="email"
                    type="email"
                    placeholder="email address"
                  />
                  {touched.email && errors.email && (
                    <div className="text-red-500 text-xs italic mt-2">{errors.email}</div>
                  )}
                  {error && <p className="text-red-500 text-xs italic mt-2">{error.message}</p>}
                </div>
                <div>
                  <button
                    disabled={loading}
                    className="bg-primary hover:bg-white hover:text-primary text-white font-bold py-2 px-4 border border-primary rounded"
                    type="submit"
                  >
                    Invite
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="w-full py-10">
            {invitees.length > 0 &&
              invitees.map((invitee) => (
                <div className="flex space-x-12" key={invitee.uid}>
                  <span>{invitee.email}</span>
                  {getInviteeAction(invitee, onRemoveInvitee)}
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const getInviteeAction = (invitee, onRemoveInvitee) => {
  return invitee.isOwner ? (
    <span className="text-sm">{'(Owner)'}</span>
  ) : invitee.isCurrentUser ? (
    <span className="text-sm">{'(You)'}</span>
  ) : (
    <span
      className="cursor-pointer underline text-sm"
      onClick={async () => {
        await onRemoveInvitee(invitee.uid)
      }}
    >
      remove from project
    </span>
  )
}

export default Collaborators
