import React, { memo } from 'react'
import classnames from 'classnames'

import ErrorMessage from '../ErrorMessage'

const Field = memo(
  ({ label, labelClass, wrapperClass, children, onChange, htmlFor, inline, reverse, errorMessage, required }) => {
    const wrapperClasses = classnames('flex', {
      'flex-row-reverse justify-end': inline && reverse,
      'flex-col': !inline && !reverse,
      'flex-col-reverse': !inline && reverse,
      'items-center': inline,
      'justify-center': !inline,
      [wrapperClass]: wrapperClass,
    })

    return (
      <div onClick={onChange} htmlFor={htmlFor} className={wrapperClasses}>
        <div className="flex flex-col justify-between">
          {label && (
            <span
              className={classnames({
                required: required,
                [labelClass]: labelClass,
              })}
            >
              {label}
            </span>
          )}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
        {children}
      </div>
    )
  }
)

export default Field
