const api = async (url, { headers, ...rest } = {}) => {
  let response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...rest,
  })

  if (!response.ok) {
    let responseError
    try {
      responseError = await response.json()
    } catch (err) {
      responseError = await response.text()
    }

    throw Error(responseError.error || responseError)
  }

  return response.json()
}

export default api
