/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { removeDuplicates, listToString } from '../../util/listOperations'

const ValuePropositionSentence = ({ customer, customerIndex, setActiveTab, setActiveQuestion }) => {
  const [byValue, setByValue] = useState(customer?.by)
  const [andValue, setAndValue] = useState(customer?.and)
  const [unlikeValue, setUnlikeValue] = useState(customer?.unlike)

  return (
    <div className="flex flex-col w-full max-w-6xl mx-auto items-center mb-12 xxl:mb-4">
      <div className="flex items-end w-full max-w-4xl mb-4">
        <h1 className="pr-4">Our</h1>
        <h2 className="flex border-b-4 border-dark pt-4 mb-4 w-full">
          {listToString(removeDuplicates(customer?.solutionDescription || []))}
        </h2>
        <button
          className="bg-gray-400 hover:bg-white text-xs text-white hover:text-gray-500 py-1 px-2 ml-2 mb-6 border border-gray-500 rounded-full"
          onClick={() => {
            setActiveTab(0)
            setActiveQuestion('beneficiaries-q7-' + customerIndex)
          }}
        >
          &rarr;
        </button>
      </div>
      <div className="flex items-end w-full max-w-4xl mb-4">
        <h1 className="pr-4">help(s)</h1>
        <h2 className="flex border-b-4 border-dark pt-4 mb-4 w-full">{customer?.name}</h2>
        <button
          className="bg-gray-400 hover:bg-white text-xs text-white hover:text-gray-500 py-1 px-2 ml-2 mb-6 border border-gray-500 rounded-full"
          onClick={() => {
            setActiveTab(0)
            setActiveQuestion('beneficiaries-tabs')
          }}
        >
          &rarr;
        </button>
      </div>
      <div className="flex flex-col w-full max-w-4xl mb-4">
        <h1 className="pr-4">who want to</h1>
        <div className="flex items-end w-full">
          <h2 className="flex border-b-4 border-dark pt-4 mb-4 w-full">
            {listToString(removeDuplicates(customer?.importantThings || []))}
          </h2>
          <button
            className="bg-gray-400 hover:bg-white text-xs text-white hover:text-gray-500 py-1 px-2 ml-2 mb-6 border border-gray-500 rounded-full"
            onClick={() => {
              setActiveTab(0)
              setActiveQuestion('beneficiaries-q4a-' + customerIndex)
            }}
          >
            &rarr;
          </button>
        </div>
      </div>
      <div className="flex items-end w-full max-w-4xl mb-4">
        <h1 className="pr-4">by</h1>
        <div className="border-b-4 border-dark pt-4 mb-4 w-full max-w-1/2 mr-8">
          <div className="input-sizer stacked" data-value={byValue}>
            <Field
              name={`customers.${customerIndex}.by`}
              placeholder="verb - Improving / reducing"
              type="textarea"
              component="textarea"
              onInput={(event) => setByValue(event.target.value)}
              rows="1"
            />
          </div>
        </div>
        <h2 className="flex border-b-4 border-dark pt-4 mb-4 w-full max-w-1/2">
          {listToString(removeDuplicates(customer?.pains || []))}
        </h2>
        <button
          className="bg-gray-400 hover:bg-white text-xs text-white hover:text-gray-500 py-1 px-2 ml-2 mb-6 border border-gray-500 rounded-full"
          onClick={() => {
            setActiveTab(0)
            setActiveQuestion('beneficiaries-q4b-' + customerIndex)
          }}
        >
          &rarr;
        </button>
      </div>

      <div className="flex items-end w-full max-w-4xl mb-4">
        <h1 className="pr-4">and</h1>
        <div className="border-b-4 border-dark pt-4 mb-4 w-full max-w-1/2 mr-8">
          <div className="input-sizer stacked" data-value={andValue}>
            <Field
              name={`customers.${customerIndex}.and`}
              placeholder="verb - Increasing / Adding"
              type="textarea"
              component="textarea"
              onInput={(event) => setAndValue(event.target.value)}
              rows="1"
            />
          </div>
        </div>
        <h2 className="flex border-b-4 border-dark pt-4 mb-4 w-full max-w-1/2">
          {listToString(removeDuplicates(customer?.valueGained || []))}
        </h2>
        <button
          className="bg-gray-400 hover:bg-white text-xs text-white hover:text-gray-500 py-1 px-2 ml-2 mb-6 border border-gray-500 rounded-full"
          onClick={() => {
            setActiveTab(0)
            setActiveQuestion('beneficiaries-q4c-' + customerIndex)
          }}
        >
          &rarr;
        </button>
      </div>
      <div className="flex items-end w-full max-w-4xl text-gray-500 mb-4">
        <h1 className="pr-4">(unlike</h1>
        <div className="border-b-4 border-gray-500 pt-2 mb-2 w-full mr-2">
          <div className="input-sizer stacked" data-value={unlikeValue}>
            <Field
              name={`customers.${customerIndex}.unlike`}
              placeholder="competing value proposition"
              type="textarea"
              component="textarea"
              onInput={(event) => setUnlikeValue(event.target.value)}
              rows="1"
            />
          </div>
        </div>
        <h1> )</h1>
      </div>
    </div>
  )
}

export default ValuePropositionSentence
