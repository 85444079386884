import React, { useEffect, createRef, useRef } from 'react'
import { Field, FieldArray } from 'formik'
import { minusButtonClasses, plusButtonClasses } from './questions'
import Tooltip from './Tooltip'
import Footer from './Footer'

export const defaultBusinessConsiderationsValues = {
  valuePropFinancial: [''],
  valuePropPhysical: [''],
  valuePropHR: [''],
  valuePropIP: [''],
  valuePropTech: [''],
  importantActivities: [''],
  expensiveResources: [''],
  expensiveActivities: [''],
}

const BusinessConsiderations = ({ values, activeQuestion, setActiveQuestion }) => {
  const businessConsiderations = values.businessConsiderations || { ...defaultBusinessConsiderationsValues }

  const refNames = ['key-resources', 'key-activities', 'mission-cost']
  const refs = useRef([])
  refs.current = refNames.map((ref, index) => (refs.current[index] = createRef()))

  const getIndex = (refName) => {
    return refNames.indexOf(refName)
  }

  useEffect(() => {
    if (activeQuestion != null) {
      const aqIndex = getIndex(activeQuestion)
      const refCurrent = refs.current[aqIndex].current
      refCurrent.className = refCurrent.className + ' border border-secondary rounded bg-blue-100 py-3 px-2 mb-3'
      refCurrent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      setActiveQuestion(null)
    }
  }, [])

  return (
    <>
      <div className="border-l border-b border-r border-secondary px-4 relative">
        <Tooltip>
          Business considerations are all the components of building a business that you need to consider, e.g.
          financial, legal, IP. They are critical components needed to successfully transform your idea into impact.
        </Tooltip>
        <div className="mb-4 pt-4 mr-32">
          <h2>Key Resources</h2>
          <h3>Assets that are required to operate and deliver your value proposition.</h3>
        </div>
        <div ref={refs.current[0]}>
          <div className="mb-4 mr-32">
            <p className="block text-sm font-medium mb-2">
              What financial resources do you need to deliver your value propositions? (i.e. seed capital, grant
              funding, etc.)
            </p>
            <FieldArray
              name={'businessConsiderations.valuePropFinancial'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.valuePropFinancial &&
                    businessConsiderations.valuePropFinancial.length > 0 &&
                    businessConsiderations.valuePropFinancial.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <Field
                          className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                          type="text"
                          placeholder="Answer"
                          name={`businessConsiderations.valuePropFinancial.${index}`}
                        />
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
          <div className="mb-4 mr-32">
            <p className="block text-sm font-medium mb-2">
              What physical assets do you need to deliver your value propositions?
            </p>
            <FieldArray
              name={'businessConsiderations.valuePropPhysical'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.valuePropPhysical &&
                    businessConsiderations.valuePropPhysical.length > 0 &&
                    businessConsiderations.valuePropPhysical.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <Field
                          className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                          type="text"
                          placeholder="Answer"
                          name={`businessConsiderations.valuePropPhysical.${index}`}
                        />
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
          <div className="mb-4 mr-32">
            <p className="block text-sm font-medium mb-2">
              What human resources do you need to deliver your value propositions?
            </p>
            <FieldArray
              name={'businessConsiderations.valuePropHR'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.valuePropHR &&
                    businessConsiderations.valuePropHR.length > 0 &&
                    businessConsiderations.valuePropHR.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <Field
                          className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                          type="text"
                          placeholder="Answer"
                          name={`businessConsiderations.valuePropHR.${index}`}
                        />
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
          <div className="mb-4 mr-32">
            <p className="block text-sm font-medium mb-2">
              What intellectual property do you need to deliver your value propositions?
            </p>
            <FieldArray
              name={'businessConsiderations.valuePropIP'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.valuePropIP &&
                    businessConsiderations.valuePropIP.length > 0 &&
                    businessConsiderations.valuePropIP.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <Field
                          className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                          type="text"
                          placeholder="Answer"
                          name={`businessConsiderations.valuePropIP.${index}`}
                        />
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
          <div className="mb-4 mr-32">
            <p className="block text-sm font-medium mb-2">
              What technology do you need to deliver your value propositions?
            </p>
            <FieldArray
              name={'businessConsiderations.valuePropTech'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.valuePropTech &&
                    businessConsiderations.valuePropTech.length > 0 &&
                    businessConsiderations.valuePropTech.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <Field
                          className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                          type="text"
                          placeholder="Answer"
                          name={`businessConsiderations.valuePropTech.${index}`}
                        />
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
        </div>

        <div className="mb-4 mr-32">
          <h2>Key Activities</h2>
          <h3>Assets that are required to operate and deliver your value proposition.</h3>
        </div>
        <div ref={refs.current[1]}>
          <div className="mb-4 mr-32">
            <p className="block text-sm font-medium mb-2">
              What are the most important activities to make the business work?
            </p>
            <FieldArray
              name={'businessConsiderations.importantActivities'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.importantActivities &&
                    businessConsiderations.importantActivities.length > 0 &&
                    businessConsiderations.importantActivities.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <Field
                          className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                          type="text"
                          placeholder="Answer"
                          name={`businessConsiderations.importantActivities.${index}`}
                        />
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
        </div>
        <div ref={refs.current[2]}>
          <div className="mb-4 mr-32">
            <div className="flex w-full">
              <div className="w-1/2">
                <p className="block text-sm font-medium mb-2">Which key resources are most expensive?</p>
              </div>
              <div className="w-1/2">
                <p className="block text-sm font-medium mb-2">How much does it cost? (i.e. dollars, time, etc.)</p>
              </div>
            </div>

            <FieldArray
              name={'businessConsiderations.expensiveResources'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.expensiveResources &&
                    businessConsiderations.expensiveResources.length > 0 &&
                    businessConsiderations.expensiveResources.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <div className="flex flex-grow">
                          <div className="w-1/2">
                            <Field
                              className="w-full shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                              type="text"
                              placeholder="Answer"
                              name={`businessConsiderations.expensiveResources.${index}.value`}
                            />
                          </div>
                          <div className="w-1/2 pl-4">
                            <Field
                              className="w-full shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                              type="text"
                              placeholder="Answer"
                              name={`businessConsiderations.expensiveResources.${index}.dollarValue`}
                            />
                          </div>
                        </div>
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
          <div className="mb-4 mr-32">
            <div className="flex w-full">
              <div className="w-1/2">
                <p className="block text-sm font-medium mb-2">Which key activities are most expensive?</p>
              </div>
              <div className="w-1/2">
                <p className="block text-sm font-medium mb-2">How much does it cost? (i.e. dollars, time, etc.)</p>
              </div>
            </div>
            <FieldArray
              name={'businessConsiderations.expensiveActivities'}
              render={(arrayHelpers) => (
                <div>
                  {businessConsiderations.expensiveActivities &&
                    businessConsiderations.expensiveActivities.length > 0 &&
                    businessConsiderations.expensiveActivities.map((x, index) => (
                      <div key={index} className="flex items-start">
                        <div className="flex flex-grow">
                          <div className="w-1/2">
                            <Field
                              className="w-full shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                              type="text"
                              placeholder="Answer"
                              name={`businessConsiderations.expensiveActivities.${index}.value`}
                            />
                          </div>
                          <div className="w-1/2 pl-4">
                            <Field
                              className="w-full shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                              type="text"
                              placeholder="Answer"
                              name={`businessConsiderations.expensiveActivities.${index}.dollarValue`}
                            />
                          </div>
                        </div>
                        <button className={minusButtonClasses} type="button" onClick={() => arrayHelpers.remove(index)}>
                          -
                        </button>
                      </div>
                    ))}
                  <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                    +
                  </button>
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BusinessConsiderations
