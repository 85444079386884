import React from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'

import { db } from '../util/firebase'
import Loader from '../components/loader'
import getDisplayName from '../util/getDisplayName'
import Invite from '../pages/invite'

const withInvite = (Component) => {
  Component.displayName = `withInvite(${getDisplayName(Component)})`

  function InviteWrapper(props) {
    const [userDoc, userLoading, userError] = useDocument(db.doc(`users/${props.authUser.uid}`))

    if (userError) {
      return (
        <div>
          <p>userError: {userError.stack || userError}</p>
        </div>
      )
    }

    if (userLoading) {
      return <Loader />
    }

    if (userDoc && userDoc.exists) {
      return <Component { ...props } userData={{ id: userDoc.id, ...userDoc.data() }}/>
    }

    return <Invite {...props} />
  }

  return InviteWrapper
}

export default withInvite
