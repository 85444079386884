import React, { useState } from 'react'
import { Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import { get, filter, isEmpty } from 'lodash'
import { minusButtonClasses, plusButtonClasses, activePillClasses, inactivePillClasses } from './questions'
import Tooltip from './Tooltip'
import Footer from './Footer'

export const defaultCompetitorValues = {
  name: '',
  sector: '',
  products: [''],
  customers: [''],
  reach: [''],
  relationships: [''],
  strengths: [''],
  weakness: [''],
  pricing: [''],
  revenueModel: '',
  partners: [''],
  valueProp: '',
  valuePropDif: '',
}

Yup.addMethod(Yup.array, 'unique', function (path, message) {
  return this.test('unique', '', function (list) {
    const errors = []
    list.forEach((item, index) => {
      const nameValue = get(item, path)

      if (nameValue && filter(list, [path, nameValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${path}`,
            message,
          })
        )
      }
    })

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors)
    }

    return true
  })
})

export const competitorsValidation = Yup.array(
  Yup.object({
    name: Yup.string().required('Required'),
    products: Yup.array(Yup.string()),
    customers: Yup.array(Yup.string()).compact(),
    reach: Yup.array(Yup.string()).compact(),
    relationships: Yup.array(Yup.string()).compact(),
    strengths: Yup.array(Yup.string()).compact(),
    weakness: Yup.array(Yup.string()).compact(),
    pricing: Yup.array(Yup.string()).compact(),
    partners: Yup.array(Yup.string()).compact(),
  })
).unique('name', 'Name must be unique')

const Competitors = ({ values, wording, touched, dirty, isSubmitting, isValid, errors }) => {
  const Competitors = values.competitors || { ...defaultCompetitorValues }
  const [activeCompetitorTab, setActiveCompetitorTab] = useState(0)

  return (
    <>
      <div className="border-l border-b border-r border-secondary px-4 relative">
        <Tooltip>
          Competitors are organisations, people, or anyone else offering a solution to the problem you are trying to
          solve. Think broadly about competitors. Sometimes competitors offer an entirely different solution to yours,
          but it is still focused on solving the same problem.
        </Tooltip>
        <FieldArray
          name="competitors"
          render={(arrayHelpers) => (
            <div className="pt-3">
              <ul className="flex flex-wrap pt-3">
                {Competitors &&
                  Competitors.length > 0 &&
                  Competitors.map((competitor, competitorIndex) => (
                    <li
                      key={`competitor-tab-${competitorIndex}`}
                      className={`mr-4 ${activeCompetitorTab === competitorIndex ? '-mb-px' : ''}`}
                    >
                      <div
                        onClick={() => setActiveCompetitorTab(competitorIndex)}
                        className={activeCompetitorTab === competitorIndex ? activePillClasses : inactivePillClasses}
                      >
                        <span>{Competitors[competitorIndex].name || `Competitor ${competitorIndex + 1}`}</span>

                        {touched.competitors &&
                          touched.competitors[competitorIndex] &&
                          Object.keys(touched.competitors[competitorIndex]).length > 0 &&
                          errors.competitors &&
                          errors.competitors[competitorIndex] &&
                          Object.keys(errors.competitors[competitorIndex]).length > 0 && (
                            <span className="ml-1 pt-1 text-red-500 text-md italic leading-none">*</span>
                          )}

                        {activeCompetitorTab === competitorIndex && Competitors.length > 1 && (
                          <a
                            className="ml-4"
                            onClick={() => {
                              const confirm = window.confirm(
                                'Are you sure you want to delete this tab? All of its content will be lost if you proceed.'
                              )
                              if (confirm == true) {
                                arrayHelpers.remove(competitorIndex)
                                setActiveCompetitorTab(0)
                              }
                            }}
                            title="Remove"
                          >
                            <i className="fas fa-trash text-red-300" />
                          </a>
                        )}
                      </div>
                    </li>
                  ))}

                <li className={`mr-1`}>
                  <button
                    className={plusButtonClasses}
                    type="button"
                    disabled={dirty || isSubmitting || !isValid}
                    onClick={() => {
                      arrayHelpers.push({ ...defaultCompetitorValues })
                      setActiveCompetitorTab(Competitors.length)
                    }}
                  >
                    +
                  </button>
                </li>
              </ul>

              <p className="text-xs italic">
                {dirty || isSubmitting || !isValid ? (
                  <>
                    Please <button className="underline text-xs italic">save changes</button> to add new competitor tab.
                    A nonduplicate Name is required.
                  </>
                ) : (
                  ''
                )}
              </p>

              {Competitors &&
                Competitors.length > 0 &&
                Competitors.map((competitor, competitorIndex) => (
                  <div
                    key={`competitor-${competitorIndex}`}
                    className={`${competitorIndex === activeCompetitorTab ? '' : 'hidden'}`}
                  >
                    <div className="flex flex-wrap w-full mt-6">
                      <div className="mb-4  w-full mr-32">
                        <div className="mb-4">
                          <p className="block text-sm font-medium mb-2">1. Name</p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`competitors.${competitorIndex}.name`}
                            placeholder="Competitor name"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`competitors.${competitorIndex}.name`}
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <div className="mb-4">
                          <p className="block text-sm font-medium mb-2">
                            2. What sector does the competitor operate in?
                          </p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`competitors.${competitorIndex}.sector`}
                            placeholder="Sector"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`competitors.${competitorIndex}.sector`}
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">
                          3. What are the products/services these competitors offer which compete with yours?
                        </p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.products`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].products &&
                                Competitors[competitorIndex].products.length > 0 &&
                                Competitors[competitorIndex].products.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.products.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">4. What {wording.csType} are they targeting?</p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.customers`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].customers &&
                                Competitors[competitorIndex].customers.length > 0 &&
                                Competitors[competitorIndex].customers.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.customers.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">
                          5. How does this competitor reach their {wording.csType}? (i.e. through TV ads, via social
                          media, cold calling, etc.)
                        </p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.reach`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].reach &&
                                Competitors[competitorIndex].reach.length > 0 &&
                                Competitors[competitorIndex].reach.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.reach.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">
                          6. How does your competitor maintain relationships with their {wording.csType}?
                        </p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.relationships`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].relationships &&
                                Competitors[competitorIndex].relationships.length > 0 &&
                                Competitors[competitorIndex].relationships.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.relationships.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">
                          7. What can your competitors do that you can't?
                        </p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.strengths`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].strengths &&
                                Competitors[competitorIndex].strengths.length > 0 &&
                                Competitors[competitorIndex].strengths.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.strengths.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">
                          8. What can you do that your competitors can't?
                        </p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.weakness`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].weakness &&
                                Competitors[competitorIndex].weakness.length > 0 &&
                                Competitors[competitorIndex].weakness.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.weakness.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">
                          9. How are your competitors pricing their solution? (i.e. monthly subscription, low upfront
                          with ongoing maintenance, giving it away for free, etc.)
                        </p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.pricing`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].pricing &&
                                Competitors[competitorIndex].pricing.length > 0 &&
                                Competitors[competitorIndex].pricing.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.pricing.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <div className="mb-4">
                          <p className="block text-sm font-medium mb-2">10. What’s your competitor’s revenue model?</p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`competitors.${competitorIndex}.revenueModel`}
                            placeholder="Answer"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`competitors.${competitorIndex}.revenueModel`}
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block text-sm font-medium mb-2">
                          11. Who are their major partners for delivering {wording.competitors_Q11}?
                        </p>
                        <FieldArray
                          name={`competitors.${competitorIndex}.partners`}
                          render={(arrayHelpers) => (
                            <div>
                              {Competitors[competitorIndex].partners &&
                                Competitors[competitorIndex].partners.length > 0 &&
                                Competitors[competitorIndex].partners.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`competitors.${competitorIndex}.partners.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <div className="mb-4">
                          <p className="block text-sm font-medium mb-2">
                            12. What is your competitors value proposition?
                          </p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`competitors.${competitorIndex}.valueProp`}
                            placeholder="Answer"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`competitors.${competitorIndex}.valueProp`}
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <div className="mb-4">
                          <p className="block text-sm font-medium mb-2">13. How is your value proposition different?</p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`competitors.${competitorIndex}.valuePropDif`}
                            placeholder="Answer"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`competitors.${competitorIndex}.valuePropDif`}
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        />
      </div>
      <Footer />
    </>
  )
}

export default Competitors
