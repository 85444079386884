import React, { useMemo } from 'react'
import classnames from 'classnames'
import Modal, { useModal } from '../Modal'

const Section = ({
  title: sectionTitle,
  icon,
  items,
  className,
  activeTab,
  setActiveTab,
  relatedTab,
  relatedRef,
  activeQuestion,
  setActiveQuestion,
}) => {
  const { isOpen, updateOpenModal } = useModal(false)

  const sectionContent = useMemo(
    () => (
      <>
        <div className="flex justify-between items-center mb-4">
          <span className="font-bold">{sectionTitle}</span>
          {icon}
        </div>
        {items?.length > 0 && (
          <div className="flex flex-col pb-8">
            {items.map((item, index) => (
              <span key={`${sectionTitle}-${index}`}>{item.value ? item.value : item}</span>
            ))}
          </div>
        )}
      </>
    ),
    [sectionTitle, icon, items]
  )

  return (
    <>
      <div
        className={classnames('box cursor-pointer relative border border-dark', { [className]: !!className })}
        onClick={() => updateOpenModal(true)}
      >
        {sectionContent}
        <button
          className="absolute bottom-0 right-0 bg-white hover:bg-gray-400 text-xs text-gray-500 hover:text-white py-1 px-2 mb-2 mr-2 border border-gray-500 rounded-full w-24"
          onClick={() => {
            setActiveTab(relatedTab)
            relatedRef && setActiveQuestion(relatedRef)
          }}
        >
          Questions &rarr;
        </button>
      </div>
      <Modal open={isOpen} onRequestClose={() => updateOpenModal(false)}>
        <div className="bg-white text-textDark px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex flex-col">{sectionContent}</div>
        </div>
      </Modal>
    </>
  )
}

export default Section
