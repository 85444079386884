import React, { useState, useCallback, useMemo, memo } from 'react'
import { Formik, Form } from 'formik'
// import { Debug } from './Debug'

const Wizard = memo(({ pages, initialValues, onSubmit, onBack, validationSchema, error }) => {
  const [page, setPage] = useState(0)
  const activePage = useMemo(() => pages[page], [pages, page])
  const isLastPage = useMemo(() => page === pages.length - 1, [pages, page])
  const [isDone, setIsDone] = useState(false)

  const initialVisitedPages = useMemo(() => pages.reduce((accum, _, index) => ({ ...accum, [index]: false }), {}), [
    pages,
  ])
  const [visitedPages, setTouchedPage] = useState(initialVisitedPages)

  const next = useCallback(() => setPage(Math.min(page + 1, pages.length - 1)), [page, pages])
  const previous = useCallback(() => setPage(Math.max(page - 1, 0)), [page])

  const handleSubmit = async (values, bag) => {
    if (isLastPage) {
      const response = await onSubmit(values)
      if (response) {
        setIsDone(true)
      }
      bag.setSubmitting(true)
      setTouchedPage({ ...visitedPages, [page]: true })
    } else {
      bag.setSubmitting(false)

      // The pre-submit of formik touchs per default all the fields, so it's necessary to refresh the fields
      // that correspond to pages that are not yet touched
      Object.keys(visitedPages).forEach((visitedPage) => {
        if (!visitedPages[visitedPage] && visitedPage === page) {
          setTouchedPage({ ...visitedPages, [page]: true })
        }
        if (!visitedPages[visitedPage] && visitedPage > page) {
          Object.keys(validationSchema[visitedPage].fields).forEach((fieldId) =>
            bag.setFieldTouched(String(fieldId), false)
          )
        }
      })

      next()
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema[page]} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <>
          <Form className="w-full text-textDark">
            {isDone ? (
              <div>Thank you, we have received your answers!</div>
            ) : (
              <>
                {activePage}
                {error && isLastPage && <div>There is an error</div>}
                <div className="flex justify-end">
                  <button
                    className="hover:bg-primary text-primary hover:text-white py-2 px-8 border border-primary rounded-full mr-2"
                    onClick={onBack}
                  >
                    Cancel
                  </button>
                  {page > 0 && (
                    <button
                      className="bg-transparent hover:bg-primary text-primary font-semibold hover:text-white py-2 px-4 border border-primary hover:border-transparent rounded-full mr-2"
                      type="button"
                      onClick={previous}
                    >
                      Previous
                    </button>
                  )}

                  {!isLastPage && (
                    <button
                      className="bg-transparent hover:bg-primary text-primary font-semibold hover:text-white py-2 px-4 border border-primary hover:border-transparent rounded-full"
                      type="submit"
                    >
                      Next
                    </button>
                  )}
                  {isLastPage && (
                    <button
                      className="bg-transparent hover:bg-primary text-primary font-semibold hover:text-white py-2 px-4 border border-primary hover:border-transparent rounded-full"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </>
            )}
            {/* <Debug /> */}
          </Form>
        </>
      )}
    </Formik>
  )
})

export default Wizard
