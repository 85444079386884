import React from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'

import { db } from '../util/firebase'
import Loader from '../components/loader'
import getDisplayName from '../util/getDisplayName'

const withOrg = (Component) => {
  Component.displayName = `withOrg(${getDisplayName(Component)})`

  function OrgWrapper(props) {
    const [orgDoc, orgLoading, orgError] = useDocument(db.doc(`organisations/${props.userData.organisation.id}`))

    if (orgError) {
      return (
        <div>
          <p>orgError: {orgError.stack || orgError}</p>
        </div>
      )
    }

    if (orgLoading) {
      return <Loader />
    }

    if (orgDoc && orgDoc.exists) {
      return <Component {...props} orgData={{ id: orgDoc.id, ...orgDoc.data() }} />
    }

    return <div>You are not associated with an organisation. Please contact support.</div>
  }

  return OrgWrapper
}

export default withOrg
