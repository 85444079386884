import React, { useCallback } from 'react'

import useOptionCheck from './useOptionCheck'
import InputField from '../InputField'
import Radiobox from '../Radiobox'
import Field from '../Field'

const RadioGroup = ({
  selectedOption,
  onChange,
  options,
  label,
  errorMessage,
  required,
  includeOptInput,
  handleTouch,
}) => {
  const { valueOptInputField, optQuestionChecked, toggleOptInput, onChangeOptInput, inputRef } = useOptionCheck(
    selectedOption,
    onChange,
    'opt_question',
    handleTouch
  )

  const onChangeRadiobox = useCallback(
    (option) => () => {
      if (optQuestionChecked) {
        toggleOptInput()
      } else {
        handleTouch(true)
      }
      onChange(option)
    },
    [onChange, optQuestionChecked, toggleOptInput, handleTouch]
  )

  return (
    <Field labelClass="mb-4 font-bold" label={label} errorMessage={errorMessage} required={required}>
      {options &&
        options.map((option) => (
          <Radiobox
            key={option.id}
            label={option.name}
            labelClass="ml-2"
            wrapperClass="mb-2"
            checked={!!selectedOption && !optQuestionChecked && selectedOption.value === option.value}
            onChange={onChangeRadiobox(option)}
          />
        ))}
      {includeOptInput && (
        <div className="flex items-center mb-2">
          <Radiobox
            id="opt_question_radio"
            key="opt_question"
            label="Other"
            checked={optQuestionChecked}
            onChange={toggleOptInput}
            labelClass="ml-2 mr-2"
          />

          <InputField
            id="opt_question_input"
            value={valueOptInputField}
            label=""
            onChange={onChangeOptInput}
            disabled={!optQuestionChecked}
            onBlur={() => handleTouch(true)}
            placeholder={includeOptInput.placeholder}
            forwardedRef={inputRef}
          />
        </div>
      )}
    </Field>
  )
}

export default RadioGroup
