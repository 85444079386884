import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Nav from '../components/nav'
import withAuth from '../hoc/withAuth'
import withInvite from '../hoc/withInvite'
import withOrg from '../hoc/withOrg'
import SubmissionList from './submission-list'

function Root({ userData, signOut, authUser, orgData }) {
  // Set organisation color theme
  document.documentElement.style.setProperty('--color-primary', orgData.primary_colour || '#2C4CE0')
  document.documentElement.style.setProperty('--color-secondary', orgData.secondary_colour || '#e2e8f0')
  document.documentElement.style.setProperty('--color-dark', orgData.base_colour || '#283E5F')

  return (
    <div className="root h-full">
      <Nav userData={userData} signOut={signOut} orgData={orgData} />
      <Switch>
        <Route
          path="/"
          render={() => <SubmissionList userData={userData} authUser={authUser} orgData={orgData} />}
          exact
        />
        <Route
          path="/submissions/:subId"
          render={() => <SubmissionList userData={userData} authUser={authUser} orgData={orgData} />}
        />
        <Route path="*" render={() => <SubmissionList userData={userData} authUser={authUser} orgData={orgData} />} />
      </Switch>
    </div>
  )
}

export default withAuth(withInvite(withOrg(Root)))
