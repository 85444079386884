import React from 'react'

import { db } from '../util/firebase'
import Loader from '../components/loader'
import Questions from '../components/questions'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import ProjectWording from '../components/ProjectWording'

const Submission = ({ authUser, submissionId, onBack }) => {
  const submissionRef = db.doc(`submissions/${submissionId}`)
  const [value, loading, error] = useDocumentData(submissionRef)

  const wording = ProjectWording(value?.modalAnswerQuestions.projectType.type)

  if (error) {
    return (
      <div className="px-20 py-4">
        <p className="mb-4">
          Something went wrong, if you were provided with a project link please double check the url.
        </p>
        <button
          className="hover:bg-primary text-primary hover:text-white py-2 px-8 border border-primary rounded-full mr-2 mb-4"
          onClick={onBack}
        >
          Return to submissions
        </button>
        <p>submissionError: {error.stack || error}</p>
      </div>
    )
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="px-20">
      <Questions
        submissionId={submissionId}
        submissionRef={submissionRef}
        authUser={authUser}
        initialValues={value}
        wording={wording}
        onBack={onBack}
        onSave={async () => {}}
      />
    </div>
  )
}

export default Submission
