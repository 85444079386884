import { useEffect } from 'react'

export default function useLockBodyScroll(enabled = true) {
  useEffect(() => {
    if (enabled) {
      document.body.style.overflow = 'hidden'

      return () => {
        document.body.style.overflow = 'auto'
      }
    }
  }, [enabled])
}
