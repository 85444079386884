import React, { useState } from 'react'
import { activePillClasses, inactivePillClasses } from './questions'
import ValuePropositionSentence from './ValuePropositionSentence'
import Tooltip from './Tooltip'
import Footer from './Footer'

const ValuePropCanvas = ({ values, wording, activeTab, setActiveTab, activeQuestion, setActiveQuestion }) => {
  const customers = values && values.customers
  const [activeCustomerTab, setActiveCustomerTab] = useState(0)

  return (
    <div className="relative">
      <Tooltip>
        Your value proposition is how your bundle of products or services create value for your customers. It is why a
        customer will start using your solution, and why they will stay or leave for other providers offering something
        different. A value proposition is specific to customer segments, so develop this with a customer segment in
        mind.
      </Tooltip>
      <div>
        <ul className="flex py-6 px-4">
          {customers &&
            customers.length > 0 &&
            customers.map((_customer, customerIndex) => (
              <li
                key={`customer-tab-${customerIndex}`}
                className={`mr-4 ${activeCustomerTab === customerIndex && '-mb-px'}`}
              >
                <div
                  onClick={() => setActiveCustomerTab(customerIndex)}
                  className={activeCustomerTab === customerIndex ? activePillClasses : inactivePillClasses}
                >
                  <span>{customers[customerIndex].name || `${wording.cTypeUpper} ${customerIndex + 1}`}</span>
                </div>
              </li>
            ))}
        </ul>
      </div>
      {customers &&
        customers.length > 0 &&
        customers.map((customer, customerIndex) => (
          <div key={`customer-${customerIndex}`} className={`${customerIndex !== activeCustomerTab && 'hidden'}`}>
            <div className="xxl:flex">
              <div className="w-full">
                <ValuePropositionSentence
                  customer={customer}
                  customerIndex={customerIndex}
                  setActiveTab={setActiveTab}
                  setActiveQuestion={setActiveQuestion}
                />
              </div>
              <div className="w-full justify-center flex mb-24 xxl:mb-0 mr-16">
                <div className="xxl:ml-16">
                  <div className="mx-8 mt-3 w-full">
                    <h2>Value Proposition</h2>
                    <div className="m-4 flex pie-canvas-container" id="pie-square-bg">
                      <div className="gains-div canvas-post-it flex text-gray-600 flex-col">
                        <div className="canvas-inner-titles text-textDark font-medium p-2">
                          <span> Gain Creators</span>
                        </div>
                        <div className="overflow-y-auto px-2 mb-4 text-xs">
                          {customers &&
                            customers[customerIndex].gains &&
                            customers[customerIndex].gains.length > 0 &&
                            customers[customerIndex].gains.map((x, index) => (
                              <div key={index} className="flex items-start ml-2 mb-2">
                                <span>{customers[customerIndex].gains[index]}</span>
                              </div>
                            ))}
                        </div>
                        <button
                          className="absolute bottom-0 right-0 bg-gray-400 hover:bg-white text-xs hover:text-gray-500 text-white py-1 px-2 mb-2 mr-2 border border-gray-500 rounded-full"
                          onClick={() => {
                            setActiveTab(0)
                            setActiveQuestion('beneficiaries-q6b-' + customerIndex)
                          }}
                        >
                          &rarr;
                        </button>
                      </div>
                      <div className="canvas-post-it solution-description-div flex text-gray-600 flex-col">
                        <div className="canvas-inner-titles text-textDark font-medium p-2">
                          <span> Products & Services</span>
                        </div>
                        <div className="overflow-y-auto px-2 mb-4 text-xs">
                          {customers &&
                            customers[customerIndex].solutionDescription &&
                            customers[customerIndex].solutionDescription.length > 0 &&
                            customers[customerIndex].solutionDescription.map((x, index) => (
                              <div key={index} className="flex items-start ml-2 mb-2">
                                <span>{customers[customerIndex].solutionDescription[index]}</span>
                              </div>
                            ))}
                        </div>
                        <button
                          className="absolute bottom-0 right-0 bg-gray-400 hover:bg-white text-xs hover:text-gray-500 text-white py-1 px-2 mb-2 mr-2 border border-gray-500 rounded-full"
                          onClick={() => {
                            setActiveTab(0)
                            setActiveQuestion('beneficiaries-q7-' + customerIndex)
                          }}
                        >
                          &rarr;
                        </button>
                      </div>
                      <div className="canvas-post-it flex alleviate-div text-gray-600 flex-col">
                        <div className="canvas-inner-titles text-textDark font-medium p-2">
                          <span> Pain Relievers</span>
                        </div>
                        <div className="overflow-y-auto px-2 mb-4 text-xs">
                          {customers &&
                            customers[customerIndex].alleviate &&
                            customers[customerIndex].alleviate.length > 0 &&
                            customers[customerIndex].alleviate.map((x, index) => (
                              <div key={index} className="flex items-start ml-2 mb-2">
                                <span>{customers[customerIndex].alleviate[index]}</span>
                              </div>
                            ))}
                        </div>
                        <button
                          className="absolute bottom-0 right-0 bg-gray-400 hover:bg-white text-xs hover:text-gray-500 text-white py-1 px-2 mb-2 mr-2 border border-gray-500 rounded-full"
                          onClick={() => {
                            setActiveTab(0)
                            setActiveQuestion('beneficiaries-q6a-' + customerIndex)
                          }}
                        >
                          &rarr;
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mx-8 mt-3 w-full">
                    <h2 className="text-right">{wording.cTypeUpper} Segment</h2>
                    <div className="m-4 flex pie-canvas-container" id="pie-circle-bg">
                      <div className="canvas-post-it flex value-gained-div text-gray-600 flex-col">
                        <div className="canvas-inner-titles text-textDark font-medium p-2">
                          <span> Gains</span>
                        </div>
                        <div className="overflow-y-auto px-2 mb-4 text-xs">
                          {customers &&
                            customers[customerIndex].valueGained &&
                            customers[customerIndex].valueGained.length > 0 &&
                            customers[customerIndex].valueGained.map((x, index) => (
                              <div key={index} className="flex items-start ml-2 mb-2">
                                <span>{customers[customerIndex].valueGained[index]}</span>
                              </div>
                            ))}
                        </div>
                        <button
                          className="absolute bottom-0 right-0 bg-gray-400 hover:bg-white text-xs hover:text-gray-500 text-white py-1 px-2 mb-2 mr-2 border border-gray-500 rounded-full"
                          onClick={() => {
                            setActiveTab(0)
                            setActiveQuestion('beneficiaries-q4c-' + customerIndex)
                          }}
                        >
                          &rarr;
                        </button>
                      </div>
                      <div className="canvas-post-it flex pains-div py-3 text-gray-600 flex-col">
                        <div className="canvas-inner-titles text-textDark font-medium p-2">
                          <span> Pains</span>
                        </div>
                        <div className="overflow-y-auto px-2 mb-4 text-xs">
                          {customers &&
                            customers[customerIndex].pains &&
                            customers[customerIndex].pains.length > 0 &&
                            customers[customerIndex].pains.map((x, index) => (
                              <div key={index} className="flex items-start ml-2 mb-2">
                                <span>{customers[customerIndex].pains[index]}</span>
                              </div>
                            ))}
                        </div>
                        <button
                          className="absolute bottom-0 right-0 bg-gray-400 hover:bg-white text-xs hover:text-gray-500 text-white py-1 px-2 mb-2 mr-2 border border-gray-500 rounded-full"
                          onClick={() => {
                            setActiveTab(0)
                            setActiveQuestion('beneficiaries-q4b-' + customerIndex)
                          }}
                        >
                          &rarr;
                        </button>
                      </div>
                      <div className="important-things-div canvas-post-it flex text-gray-600 flex-col">
                        <div className="canvas-inner-titles text-textDark font-medium p-2">
                          <span> {wording.cTypeUpper} Jobs </span>
                        </div>
                        <div className="overflow-y-auto px-2 mb-4 text-xs">
                          {customers &&
                            customers[customerIndex].importantThings &&
                            customers[customerIndex].importantThings.length > 0 &&
                            customers[customerIndex].importantThings.map((x, index) => (
                              <div key={index} className="flex items-start ml-2 mb-2">
                                <span>{customers[customerIndex].importantThings[index]}</span>
                              </div>
                            ))}
                        </div>
                        <button
                          className="absolute bottom-0 right-0 bg-gray-400 hover:bg-white text-xs hover:text-gray-500 text-white py-1 px-2 mb-2 mr-2 border border-gray-500 rounded-full"
                          onClick={() => {
                            setActiveTab(0)
                            setActiveQuestion('beneficiaries-q4a-' + customerIndex)
                          }}
                        >
                          &rarr;
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      <Footer divider="-">
        ©{' '}
        <a className="underline" href="http://Strategyzer.com" alt="Strategyzer.com" target="_New">
          Strategyzer.com
        </a>{' '}
        and Strategyzer AG
      </Footer>
    </div>
  )
}

export default ValuePropCanvas
