import React from 'react'

const AuthContainer = (props) => (
  <div className="authContainer h-full">
    <div className="flex h-full">
      <div
        className="flex flex-col w-1/2 h-screen"
        style={{
          backgroundColor: '#000000',
        }}
      >
        <div className="my-auto pb-40">
          <img src="/images/TGDLogo-white.png" alt="AI Logo" className="mx-auto h-24 mb-10" />
          <h1 className="text-white text-center px-8 hero">Tools</h1>
        </div>
      </div>
      <div className="flex flex-col w-1/2 h-full bg-white my-auto">
        <div className="my-auto pb-20">{props.children}</div>
      </div>
    </div>
  </div>
)

export default AuthContainer
