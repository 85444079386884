import React, { useState, useEffect, createRef, useRef } from 'react'
import { Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import { get, filter, isEmpty } from 'lodash'
import { minusButtonClasses, plusButtonClasses, activePillClasses, inactivePillClasses } from './questions'
import Tooltip from './Tooltip'
import Footer from './Footer'

export const defaultPartnerValues = {
  name: '',
  sector: '',
  provides: [''],
  how: [''],
  youNeed: [''],
  theyOffer: [''],
  theyOfferEvidence: '',
  youOffer: [''],
  youOfferEvidence: '',
  support: [''],
}

Yup.addMethod(Yup.array, 'unique', function (path, message) {
  return this.test('unique', '', function (list) {
    const errors = []
    list.forEach((item, index) => {
      const nameValue = get(item, path)

      if (nameValue && filter(list, [path, nameValue]).length > 1) {
        errors.push(
          this.createError({
            path: `${this.path}[${index}].${path}`,
            message,
          })
        )
      }
    })

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors)
    }

    return true
  })
})

export const partnersValidation = Yup.array(
  Yup.object({
    name: Yup.string().required('Required'),
    provides: Yup.array(Yup.string()).compact(),
    how: Yup.array(Yup.string()).compact(),
    youNeed: Yup.array(Yup.string()).compact(),
    youOffer: Yup.array(Yup.string()).compact(),
    theyOffer: Yup.array(Yup.string()).compact(),
    support: Yup.array(Yup.string()).compact(),
  })
).unique('name', 'Name must be unique')

const Partners = ({
  values,
  wording,
  touched,
  dirty,
  isSubmitting,
  isValid,
  errors,
  activeQuestion,
  setActiveQuestion,
}) => {
  const Partners = values.partners || { ...defaultPartnerValues }
  const [activePartnerTab, setActivePartnerTab] = useState(0)

  const refNames = ['partners-tabs']
  const refs = useRef([])
  refs.current = refNames.map((ref, index) => (refs.current[index] = createRef()))

  const getIndex = (refName) => {
    return refNames.indexOf(refName)
  }

  useEffect(() => {
    if (activeQuestion != null) {
      const aqIndex = getIndex(activeQuestion)
      const refCurrent = refs.current[aqIndex].current
      refCurrent.className = refCurrent.className + ' border border-secondary rounded bg-blue-100 py-3 px-2 mb-3'
      refCurrent.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      setActiveQuestion(null)
    }
  }, [])

  return (
    <>
      <div className="border-l border-b border-r border-secondary px-4 relative">
        <Tooltip>
          Partners help you develop and deploy your idea. They may be suppliers, R&D providers, policy analysts, funders
          etc. Try to identify partners that play roles you can't or don't want to.
        </Tooltip>
        <FieldArray
          name="partners"
          render={(arrayHelpers) => (
            <div className="pt-3">
              <ul id="partners-tabs" className="flex flex-wrap pt-3" ref={refs.current[0]}>
                {Partners &&
                  Partners.length > 0 &&
                  Partners.map((partner, partnerIndex) => (
                    <li
                      key={`partner-tab-${partnerIndex}`}
                      className={`mr-4 ${activePartnerTab === partnerIndex ? '-mb-px' : ''}`}
                    >
                      <div
                        onClick={() => setActivePartnerTab(partnerIndex)}
                        className={activePartnerTab === partnerIndex ? activePillClasses : inactivePillClasses}
                      >
                        <span>{Partners[partnerIndex].name || `Partner ${partnerIndex + 1}`}</span>

                        {touched.partners &&
                          touched.partners[partnerIndex] &&
                          Object.keys(touched.partners[partnerIndex]).length > 0 &&
                          errors.partners &&
                          errors.partners[partnerIndex] &&
                          Object.keys(errors.partners[partnerIndex]).length > 0 && (
                            <span className="ml-1 pt-1 text-red-500 text-md italic leading-none">*</span>
                          )}

                        {activePartnerTab === partnerIndex && Partners.length > 1 && (
                          <a
                            className="ml-4"
                            onClick={() => {
                              const confirm = window.confirm(
                                'Are you sure you want to delete this tab? All of its content will be lost if you proceed.'
                              )
                              if (confirm == true) {
                                arrayHelpers.remove(partnerIndex)
                                setActivePartnerTab(0)
                              }
                            }}
                            title="Remove"
                          >
                            <i className="fas fa-trash text-red-300" />
                          </a>
                        )}
                      </div>
                    </li>
                  ))}

                <li className={`mr-1`}>
                  <button
                    className={plusButtonClasses}
                    type="button"
                    disabled={dirty || isSubmitting || !isValid}
                    onClick={() => {
                      arrayHelpers.push({ ...defaultPartnerValues })
                      setActivePartnerTab(Partners.length)
                    }}
                  >
                    +
                  </button>
                </li>
              </ul>

              <p className="text-xs italic">
                {dirty || isSubmitting || !isValid ? (
                  <>
                    Please <button className="underline text-xs italic">save changes</button> to add new partner tab. A
                    nonduplicate Name is required.
                  </>
                ) : (
                  ''
                )}
              </p>

              {Partners &&
                Partners.length > 0 &&
                Partners.map((partner, partnerIndex) => (
                  <div
                    key={`partner-${partnerIndex}`}
                    className={`${partnerIndex === activePartnerTab ? '' : 'hidden'}`}
                  >
                    <div className="flex flex-wrap w-full mt-6">
                      <div className="mb-2 w-full mr-32">
                        <p className="block  text-sm font-medium mb-2">1. Name</p>
                        <Field
                          className="shadow appearance-none border border-secondary rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          name={`partners.${partnerIndex}.name`}
                          placeholder="Partner name"
                        />
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name={`partners.${partnerIndex}.name`}
                          component="div"
                        />
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <div className="mb-4">
                          <div className="flex flex-col">
                            <label className="items-center mt-3">
                              <Field
                                type="radio"
                                name={`partners.${partnerIndex}.type`}
                                value="partner already"
                                className="form-radio h-5 w-5 ml-4"
                              />
                              <span className="ml-2">a. I have this partner already</span>
                            </label>
                            <label className="items-center mt-3">
                              <Field
                                type="radio"
                                name={`partners.${partnerIndex}.type`}
                                value="targeting partner"
                                className="form-radio h-5 w-5 ml-4"
                              />
                              <span className="ml-2">b. I’m targeting this partner</span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <div className="mb-4">
                          <p className="block  text-sm font-medium mb-2">
                            2. What sector does this stakeholder operate in?
                          </p>
                          <Field
                            className="shadow appearance-none border border-secondary rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            name={`partners.${partnerIndex}.sector`}
                            placeholder="Sector"
                          />
                          <ErrorMessage
                            className="text-red-500 text-xs italic"
                            name={`partners.${partnerIndex}.sector`}
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block  text-sm font-medium mb-2">
                          3. What will/does this partner provide you? (i.e. technology, expertise, funding, etc.)
                        </p>
                        <FieldArray
                          name={`partners.${partnerIndex}.provides`}
                          render={(arrayHelpers) => (
                            <div>
                              {Partners[partnerIndex].provides &&
                                Partners[partnerIndex].provides.length > 0 &&
                                Partners[partnerIndex].provides.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3  leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`partners.${partnerIndex}.provides.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block  text-sm font-medium mb-2">
                          4. How does this partner help you deliver {wording.partners_q4}?
                        </p>
                        <FieldArray
                          name={`partners.${partnerIndex}.how`}
                          render={(arrayHelpers) => (
                            <div>
                              {Partners[partnerIndex].how &&
                                Partners[partnerIndex].how.length > 0 &&
                                Partners[partnerIndex].how.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3  leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`partners.${partnerIndex}.how.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block  text-sm font-medium mb-2">
                          5. What do you need to work effectively with this partner? (i.e. small funding to run
                          workshops, strong transport channels, clear governance structures, etc.)
                        </p>
                        <FieldArray
                          name={`partners.${partnerIndex}.youNeed`}
                          render={(arrayHelpers) => (
                            <div>
                              {Partners[partnerIndex].youNeed &&
                                Partners[partnerIndex].youNeed.length > 0 &&
                                Partners[partnerIndex].youNeed.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3  leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`partners.${partnerIndex}.youNeed.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <p className="block  text-sm font-medium mb-2">6.</p>
                        <div className="ml-4">
                          <p className="block  text-sm font-medium mb-2">
                            a. What can this partner offer you that no one else can?
                          </p>
                          <FieldArray
                            name={`partners.${partnerIndex}.theyOffer`}
                            render={(arrayHelpers) => (
                              <div>
                                {Partners[partnerIndex].theyOffer &&
                                  Partners[partnerIndex].theyOffer.length > 0 &&
                                  Partners[partnerIndex].theyOffer.map((x, index) => (
                                    <div key={index} className="flex items-start">
                                      <Field
                                        className="shadow appearance-none border border-secondary rounded py-2 px-3  leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                        type="text"
                                        placeholder="Answer"
                                        name={`partners.${partnerIndex}.theyOffer.${index}`}
                                      />
                                      <button
                                        className={minusButtonClasses}
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        -
                                      </button>
                                    </div>
                                  ))}
                                <button
                                  className={plusButtonClasses}
                                  type="button"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  +
                                </button>
                              </div>
                            )}
                          />
                          <div className="mt-4 mb-4">
                            <p className="block  text-sm font-medium mb-2">
                              b. What evidence do you have of this? (i.e. an anecdote from an interview, data or
                              information from research, etc.)
                            </p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`partners.${partnerIndex}.theyOfferEvidence`}
                              placeholder="Answer"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`partners.${partnerIndex}.theyOfferEvidence`}
                              component="div"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 w-full mr-32">
                        <p className="block  text-sm font-medium mb-2">7.</p>
                        <div className="ml-4">
                          <p className="block  text-sm font-medium mb-2">
                            a. What can you offer this partner that no one else can?
                          </p>
                          <FieldArray
                            name={`partners.${partnerIndex}.youOffer`}
                            render={(arrayHelpers) => (
                              <div>
                                {Partners[partnerIndex].youOffer &&
                                  Partners[partnerIndex].youOffer.length > 0 &&
                                  Partners[partnerIndex].youOffer.map((x, index) => (
                                    <div key={index} className="flex items-start">
                                      <Field
                                        className="shadow appearance-none border border-secondary rounded py-2 px-3  leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                        type="text"
                                        placeholder="Answer"
                                        name={`partners.${partnerIndex}.youOffer.${index}`}
                                      />
                                      <button
                                        className={minusButtonClasses}
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        -
                                      </button>
                                    </div>
                                  ))}
                                <button
                                  className={plusButtonClasses}
                                  type="button"
                                  onClick={() => arrayHelpers.push('')}
                                >
                                  +
                                </button>
                              </div>
                            )}
                          />
                          <div className="mt-4 mb-4">
                            <p className="block  text-sm font-medium mb-2">
                              b. What evidence do you have of this? (i.e. an anecdote from an interview, data or
                              information from research, etc.)
                            </p>
                            <Field
                              className="shadow appearance-none border border-secondary rounded w-full py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name={`partners.${partnerIndex}.youOfferEvidence`}
                              placeholder="Answer"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs italic"
                              name={`partners.${partnerIndex}.youOfferEvidence`}
                              component="div"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-6 w-full mr-32">
                        <p className="block  text-sm font-medium mb-2">
                          8. How does this partnership support your value proposition?
                        </p>
                        <FieldArray
                          name={`partners.${partnerIndex}.support`}
                          render={(arrayHelpers) => (
                            <div>
                              {Partners[partnerIndex].support &&
                                Partners[partnerIndex].support.length > 0 &&
                                Partners[partnerIndex].support.map((x, index) => (
                                  <div key={index} className="flex items-start">
                                    <Field
                                      className="shadow appearance-none border border-secondary rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline mb-2 flex-grow"
                                      type="text"
                                      placeholder="Answer"
                                      name={`partners.${partnerIndex}.support.${index}`}
                                    />
                                    <button
                                      className={minusButtonClasses}
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>
                                  </div>
                                ))}
                              <button className={plusButtonClasses} type="button" onClick={() => arrayHelpers.push('')}>
                                +
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        />
      </div>
      <Footer />
    </>
  )
}

export default Partners
