import React, { useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useParams, useHistory } from 'react-router-dom'

import { db, FieldValue } from '../util/firebase'
import Loader from '../components/loader'
import Submission from './submission'
import Modal, { useModal } from '../components/Modal'
import ProjectQuestions from '../components/ProjectQuestions'
import NewUserModal from '../components/NewUserModal'
import { defaultBusinessConsiderationsValues } from '../components/BusinessConsiderations'
import { defaultInterviewValues } from '../components/Interviews'
import Footer from '../components/Footer'

const SubmissionList = ({ userData, authUser, orgData }) => {
  const { subId } = useParams()
  const submissionsRef = db.collection('submissions')
  const userReference = db.collection('users').doc(userData.id)
  const isNewUser = authUser.metadata.creationTime === authUser.metadata.lastSignInTime

  const query = submissionsRef.where('organisation', '==', userData.organisation).orderBy('updated', 'desc')
  const [submissionsValue, submissionsLoading, submissionsError] = useCollectionData(
    userData.role && userData.role === 'super_user' ? query : query.where('members', 'array-contains', userReference),
    { idField: 'id' }
  )

  const [selectedSubmissionId, setSelectedSubmissionId] = useState(subId || null)
  const [createProject, setCreateProject] = useState(false)

  const history = useHistory()

  // new user modal
  const { isOpen: isOpenNewUserModal, toggleModal: toggleNewUserModal } = useModal(true)

  // new project modal
  const {
    isOpen: isOpenNewProjectModal,
    updateOpenModal: updateOpenNewProjectModal,
    toggleModal: toggleNewProjectModal,
  } = useModal(false)

  const initialCompetitorValues = {
    name: 'Competitor 1',
    sector: '',
    products: [''],
    customers: [''],
    reach: [''],
    relationships: [''],
    strengths: [''],
    weakness: [''],
    pricing: [''],
    revenueModel: '',
    partners: [''],
    valueProp: '',
    valuePropDif: '',
  }

  const initialPartnerValues = {
    name: 'Partner 1',
    sector: '',
    provides: [''],
    how: [''],
    youNeed: [''],
    theyOffer: [''],
    theyOfferEvidence: '',
    youOffer: [''],
    youOfferEvidence: '',
    support: [''],
  }

  const initialCustomerValues = {
    sector: '',
    quantity: '',
    importantThings: [''],
    pains: [''],
    valueGained: [''],
    valueEvidence: '',
    valueEvidences: [''],
    solutions: [''],
    alleviate: [''],
    gains: [''],
    solutionDescription: [''],
    reach: [''],
    reachEvidence: '',
    reachEvidences: [''],
    maintain: [''],
    maintainEvidence: '',
    valueProps: [''],
    benefits: [''],

    // These properties are just editable through ValuePropositionSentence
    by: '',
    and: '',
    unlike: '',
  }

  const displayNewProject = () => {
    setCreateProject(true)
    updateOpenNewProjectModal(true)
  }

  function confirmDelete(e, submission) {
    e.preventDefault()
    e.stopPropagation()
    const deleteConfirmed = window.confirm(`Permanently delete '${submission.projectName}'?`)
    if (deleteConfirmed) {
      const submissionToBeDeletedRef = db.doc(`/submissions/${submission.id}`)
      submissionToBeDeletedRef.delete().catch(console.error)
    }
  }

  if (submissionsError) {
    return (
      <div>
        <p>submissionsError: {submissionsError.stack || submissionsError}</p>
      </div>
    )
  }

  if (submissionsLoading) {
    return <Loader />
  }

  if (selectedSubmissionId) {
    return (
      <Submission
        authUser={authUser}
        submissionId={selectedSubmissionId}
        onBack={() => setSelectedSubmissionId(null, history.push(`/`))}
      />
    )
  }

  const newSubmissionRef = submissionsRef.doc()

  return (
    <div className="text-textDark">
      <div className="w-full">
        <div className="px-20 py-10 shadow border-0 border-b border-secondary">
          <div className="flex items-center justify-between">
            <h1>Projects</h1>

            <button
              className="bg-primary hover:bg-white text-white hover:text-primary py-2 px-8 border border-primary rounded-full"
              onClick={displayNewProject}
            >
              Create project
            </button>
          </div>

          {(!submissionsValue || !submissionsValue.length) && (
            <div className="pt-12">
              <p className="text-center text-textLight">Projects you create will appear here</p>
            </div>
          )}

          <div className="flex flex-wrap mt-5 -ml-4">
            {submissionsValue.map((s) => (
              <div
                key={`submission-${s.id}`}
                className="max-w-sm w-full p-4 cursor-pointer"
                onClick={() => setSelectedSubmissionId(s.id, history.push(`submissions/${s.id}`))}
              >
                <div className="rounded shadow border border-secondary bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                  <div className="mb-8 relative">
                    <div className="font-bold text-xl mb-2">{s.projectName}</div>
                    <a
                      className="absolute top-0 right-0"
                      onClick={(e) => {
                        const confirm = window.confirm(
                          'Are you sure you want to delete this project? All of its content will be lost if you proceed.'
                        )
                        if (confirm == true) {
                          confirmDelete(e, s)
                        } else {
                          e.stopPropagation()
                        }
                      }}
                      title="Remove"
                    >
                      <i className="fas fa-trash text-red-300" />
                    </a>
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm">
                      <p className="leading-none">Created by {s.createdBy ? s.createdBy : 'Project Owner'}</p>
                      <p className="text-gray-600">
                        {s.created ? s.created.toDate().toDateString() : 'Unknown'}
                        {/*{
                          TODO: created: db.FieldValue.serverTimestamp(),
                      }*/}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="px-20 py-16 text-textDark">
          <h2 className="mb-6">What is business ecosystem mapping?</h2>
          <p className="mt-4 mb-8">
            The business ecosystem map is a fundamental tool to help you identify the key stakeholders in both
            commercial and non-commercial ‘markets’ you might plan to operate in.
          </p>
          <p className="mt-4 mb-8">
            This tool helps understand the market by identifying all the key stakeholders that exist in the system and
            the relationships they have with you. By mapping your competitors, customers and partners you will be able
            to identify assumptions to test and new stakeholders you need to engage with.
          </p>
          <p className="mt-4 mb-8">
            Through the business ecosystem mapping you will be able to understand the complexity of your stakeholder
            ecosystem, the opportunities and barriers within your market, and the value proposition that distinguishes
            you from the competition.
          </p>
          <Footer />
        </div>
      </div>

      {isNewUser && <NewUserModal isOpen={isOpenNewUserModal} toggleModal={toggleNewUserModal} />}
      {createProject && (
        <Modal open={isOpenNewProjectModal}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <ProjectQuestions
                onBack={() => {
                  toggleNewProjectModal()
                  setCreateProject(false)
                }}
                onSubmit={async (values) => {
                  toggleNewProjectModal()
                  await newSubmissionRef.set(
                    {
                      created: FieldValue.serverTimestamp(),
                      owner: db.collection(`/users`).doc(userData.id),
                      createdBy: authUser.displayName,
                      organisation: userData.organisation,
                      members: [db.collection(`/users`).doc(userData.id)],
                      updated: FieldValue.serverTimestamp(),
                      projectName: values.name,
                      modalAnswerQuestions: values,
                      competitors: [initialCompetitorValues],
                      partners: [initialPartnerValues],
                      customers: [
                        {
                          ...initialCustomerValues,
                          name: values.projectType.type == 'researcher' ? 'Beneficiary 1' : 'Customer 1',
                        },
                      ],
                      businessConsiderations: defaultBusinessConsiderationsValues,
                      interviews: defaultInterviewValues,
                    },
                    { merge: true }
                  )
                  setCreateProject(false)
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default SubmissionList
