import React, { memo } from 'react'
import { useFormikContext } from 'formik'
import { isEqual } from 'lodash'

import RadioGroup from '../Form/RadioGroup'
import InputField from '../Form/InputField/'

const Page = memo(({ questions }) => {
  const { values, errors, touched, setFieldValue, setFieldTouched, handleChange, handleBlur } = useFormikContext()

  return (
    <>
      {questions &&
        questions.map((question) => {
          const { id: questionId, type, questionIndex } = question
          const errorMessage =
            touched[questionId] &&
            (errors[questionId] && Array.isArray(errors[questionId]) ? errors[questionId][0] : errors[questionId])
          const label = `${questionIndex}. ${question.label}`

          if (type === 'inputText') {
            return (
              <div className="mb-8" key={`question-${questionId}`}>
                <InputField
                  value={values[questionId]}
                  label={label}
                  onChange={handleChange(questionId)}
                  onBlur={handleBlur(questionId)}
                  errorMessage={errorMessage}
                  required={question.required}
                  placeholder={question.placeholder}
                />
              </div>
            )
          } else if (type === 'radiogroup') {
            const onChange = (option) => {
              if (!isEqual(values[questionId], option)) {
                setFieldValue(questionId, option)
              } else {
                setFieldValue(questionId, null)
              }
            }
            const handleTouch = (touched = true) => setTimeout(() => setFieldTouched(questionId, touched), 0)

            return (
              <div className="mb-8" key={`question-${questionId}`}>
                <RadioGroup
                  options={question.options}
                  selectedOption={values[questionId]}
                  onChange={onChange}
                  label={label}
                  errorMessage={errorMessage}
                  required={question.required}
                  includeOptInput={question.includeOptInput}
                  handleTouch={handleTouch}
                />
              </div>
            )
          }
        })}
    </>
  )
})

export default Page
