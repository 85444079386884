import React, { useState, useCallback } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import classnames from 'classnames'
import * as Yup from 'yup'

import DAG from './dag'
import Collaborators from './Collaborators'
import ValuePropCanvas from './ValuePropCanvas'
import BusinessConsiderations, { defaultBusinessConsiderationsValues } from './BusinessConsiderations'
import Customers, { defaultCustomerValues, customersValidation } from './Customers'
import Competitors, { defaultCompetitorValues, competitorsValidation } from './Competitors'
import Partners, { defaultPartnerValues, partnersValidation } from './Partners'
import ProjectModelCanvas from './ProjectModelCanvas'
import Interviews, { defaultInterviewValues, interviewsValidation } from './Interviews'
import { FieldValue } from '../util/firebase'

export const TABS = {
  CUSTOMERS: 0,
  PARTNERS: 1,
  COMPETITORS: 2,
  BUSINESS_CONSIDERATIONS: 3,
  VALUE_PROP_CANVAS: 4,
  DIAGRAM: 5,
  BUSINESS_MODEL_DIAGRAM: 6,
  INTERVIEWS: 7,
  COLLABORATORS: 8,
}

export const activeTabClasses = 'border-l border-t border-r rounded-t border-secondary text-primary'
export const inactiveTabClasses = 'text-textDark hover:text-primary'
export const activePillClasses =
  'cursor-pointer inline-block border border-primary rounded py-1 px-3 bg-primary hover:bg-white text-white hover:text-primary mb-4'
export const inactivePillClasses =
  'cursor-pointer inline-block border border-gray-200 rounded text-primary hover:bg-gray-200 py-1 px-3 mb-4'

export const minusButtonClasses =
  'cursor-pointer inline-block rounded hover:border-red-600 hover:text-red-600 active:border-red-600 text-red-300 py-1 px-3 ml-1 mt-1 border border-red-300'
export const plusButtonClasses =
  'cursor-pointer inline-block rounded hover:border-primary hover:text-primary active:border-primary text-primary py-1 px-3 ml-1 border border-primary disabled:opacity-50'

const ValidationSchema = Yup.object({
  projectName: Yup.string().required('Required'),
  competitors: competitorsValidation,
  partners: partnersValidation,
  customers: customersValidation,
  interviews: interviewsValidation,
})

const Tab = ({ activeTab, setActiveTab, title, tab, showError }) => {
  const handleClick = useCallback(() => setActiveTab(tab), [setActiveTab, tab])

  return (
    <li className={classnames('mr-1', { '-mb-px': activeTab === tab })}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classnames(
          'bg-white',
          'inline-block',
          'pt-2',
          'pb-4',
          'px-3',
          'xxl:py-3',
          'xxl:px-4',
          'text-sm',
          'xxl:text-base',
          'font-medium',
          'flex',
          'items-center',
          'whitespace-nowrap',
          'cursor-pointer',
          `${activeTab === tab ? activeTabClasses : inactiveTabClasses}`
        )}
        onClick={handleClick}
      >
        {title}
        {showError && <span className="ml-1 pt-1 text-red-500 text-md italic leading-none">*</span>}
      </a>
    </li>
  )
}

const Questions = ({
  initialValues,
  wording,
  defaultProjectName,
  authUser,
  submissionId,
  submissionRef,
  onBack,
  onSave,
}) => {
  const defaultValues = {
    projectName: defaultProjectName,
    competitors: [{ ...defaultCompetitorValues }],
    partners: [{ ...defaultPartnerValues }],
    customers: [{ ...defaultCustomerValues }],
    businessConsiderations: { ...defaultBusinessConsiderationsValues },
    interviews: { ...defaultInterviewValues },
  }

  const [activeTab, setActiveTab] = useState(TABS.CUSTOMERS)
  const [submitAttempted, setSubmitAttempted] = useState(false)

  const [activeQuestion, setActiveQuestion] = useState(null)

  async function saveForm(values) {
    try {
      await onSave()
      const { members, ...rest } = values
      await submissionRef.set({ ...rest, updated: FieldValue.serverTimestamp() }, { merge: true })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="questions">
      <div className="w-full">
        <div className="text-textDark rounded px-8 pt-6 pb-8 mb-4">
          <Formik
            enableReinitialize
            initialValues={initialValues || defaultValues}
            validationSchema={ValidationSchema}
            onSubmit={saveForm}
          >
            {({ values, isSubmitting, isValid, dirty, errors, touched }) => (
              <Form>
                <div className="flex items-center justify-between">
                  <div className="mb-4 w-1/3 text-textDark">
                    <Field
                      className="appearance-none w-full text-4xl bold text-textDark border-b border-secondary p-2 leading-tight focus:outline-none focus:shadow-outline"
                      type="text"
                      name="projectName"
                      placeholder="Project name"
                    />
                    <ErrorMessage className="text-red-500 text-xs italic" name="projectName" component="div" />
                  </div>

                  <div className="flex flex-row-reverse items-center">
                    <button
                      className="bg-primary hover:bg-white text-white hover:text-primary py-2 px-8 border border-primary rounded-full focus:outline-none"
                      disabled={isSubmitting}
                      onClick={() => setSubmitAttempted(true)}
                    >
                      <i class="fas fa-cloud-upload-alt pr-1" /> Save
                    </button>

                    <button
                      className={classnames(
                        'hover:bg-primary',
                        'text-primary',
                        'hover:text-white',
                        'py-2',
                        'px-8',
                        'border',
                        'border-primary',
                        'rounded-full',
                        'mr-2',
                        'focus:outline-none',
                        `${activeTab === TABS.COLLABORATORS && 'opacity-50'}`
                      )}
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => setActiveTab(TABS.COLLABORATORS)}
                    >
                      <svg
                        height="14px"
                        style={{ display: 'inline', paddingRight: '4px', marginBottom: '5px' }}
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 13"
                        fill="currentColor"
                      >
                        <path
                          d="M8,5H5V2H3v3H0v2h3v3h2V7h3V5z M18,6c1.7,0,3-1.3,3-3s-1.3-3-3-3c-0.3,0-0.6,0.1-0.9,0.1C17.7,0.9,18,1.9,18,3
                          s-0.3,2-0.9,2.9C17.4,5.9,17.7,6,18,6z M13,6c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S11.3,6,13,6z M19.6,8.2C20.5,8.9,21,9.8,21,11
                          v2h3v-2C24,9.5,21.6,8.5,19.6,8.2z M13,8c-2,0-6,1-6,3v2h12v-2C19,9,15,8,13,8z"
                        />
                      </svg>{' '}
                      Invite
                    </button>

                    <button
                      className="disabled:opacity-50 hover:bg-primary text-primary hover:text-white py-2 px-8 border border-primary rounded-full mr-2 focus:outline-none"
                      disabled={dirty || isSubmitting || !isValid}
                      onClick={onBack}
                    >
                      <i class="far fa-times-circle pr-1" /> Close
                    </button>

                    <div className="text-right inline">
                      {dirty && <p className="text-xs text-textDark italic mr-6">Your form has unsaved changes.</p>}
                      {!isValid && submitAttempted && (
                        <p className="text-red-500 text-xs italic mr-6">
                          It looks like there are errors on your form. Correct them and save again.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <ul className="flex mt-6 border-b border-secondary">
                  <Tab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab={TABS.CUSTOMERS}
                    showError={touched.customers?.length > 0 && errors.customers?.length > 0}
                    title={wording.cTitle}
                  />

                  <Tab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab={TABS.PARTNERS}
                    showError={touched.partners?.length > 0 && errors.partners?.length > 0}
                    title="Partners"
                  />

                  <Tab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab={TABS.COMPETITORS}
                    showError={touched.competitors?.length > 0 && errors.competitors?.length > 0}
                    title="Competitors"
                  />

                  <Tab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab={TABS.BUSINESS_CONSIDERATIONS}
                    title={`${wording.bTitle} Considerations`}
                  />
                  <Tab
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab={TABS.VALUE_PROP_CANVAS}
                    title="Value Proposition"
                  />

                  <Tab activeTab={activeTab} setActiveTab={setActiveTab} tab={TABS.DIAGRAM} title="Stakeholder Map" />

                  {submissionId && (
                    <>
                      <Tab
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        tab={TABS.BUSINESS_MODEL_DIAGRAM}
                        title="Business Model"
                      />
                      <Tab activeTab={activeTab} setActiveTab={setActiveTab} tab={TABS.INTERVIEWS} title="Interviews" />
                    </>
                  )}
                </ul>

                {activeTab === TABS.CUSTOMERS && (
                  <Customers
                    activeTab={activeTab}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={setActiveQuestion}
                    values={values}
                    wording={wording}
                    touched={touched}
                    dirty={dirty}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    errors={errors}
                  />
                )}
                {activeTab === TABS.PARTNERS && (
                  <Partners
                    activeTab={activeTab}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={setActiveQuestion}
                    values={values}
                    wording={wording}
                    touched={touched}
                    dirty={dirty}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    errors={errors}
                  />
                )}
                {activeTab === TABS.COMPETITORS && (
                  <Competitors
                    activeTab={activeTab}
                    values={values}
                    wording={wording}
                    touched={touched}
                    dirty={dirty}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    errors={errors}
                  />
                )}
                {activeTab === TABS.BUSINESS_CONSIDERATIONS && (
                  <BusinessConsiderations
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={setActiveQuestion}
                    values={values}
                    wording={wording}
                  />
                )}
                {activeTab === TABS.VALUE_PROP_CANVAS && (
                  <ValuePropCanvas
                    submissionId={submissionId}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={setActiveQuestion}
                    values={values}
                    wording={wording}
                  />
                )}
                {activeTab === TABS.DIAGRAM && <DAG formValues={values} wording={wording} />}
                {activeTab === TABS.BUSINESS_MODEL_DIAGRAM && (
                  <ProjectModelCanvas
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    activeQuestion={activeQuestion}
                    setActiveQuestion={setActiveQuestion}
                    values={values}
                    wording={wording}
                  />
                )}
                {submissionId && activeTab === TABS.INTERVIEWS && (
                  <Interviews
                    activeTab={activeTab}
                    values={values}
                    wording={wording}
                    touched={touched}
                    errors={errors}
                  />
                )}

                {dirty && (
                  <div className="bg-white rounded border border-secondary fixed bottom-0 right-0 py-4 px-8 mr-8 mb-8 flex flex-row-reverse items-center shadow">
                    <button
                      className="bg-primary hover:bg-white text-white hover:text-primary py-2 px-8 border border-primary rounded-full"
                      disabled={isSubmitting}
                      onClick={() => setSubmitAttempted(true)}
                    >
                      <i class="fas fa-cloud-upload-alt pr-1" /> Save
                    </button>

                    <div className="text-right inline">
                      <p className="text-xs text-textDark italic mr-6">Your form has unsaved changes.</p>
                      {!isValid && submitAttempted && (
                        <p className="text-red-500 text-xs italic mr-6">
                          It looks like there are errors on your form. Correct them and save again.
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
          {submissionId && (
            <div>
              {activeTab === TABS.COLLABORATORS && <Collaborators submissionId={submissionId} authUser={authUser} />}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Questions
