import { useState } from 'react'

const useModal = (initialState = true) => {
  const [isOpen, updateOpenModal] = useState(initialState)
  const toggleModal = () => updateOpenModal(!isOpen)

  return {
    isOpen,
    updateOpenModal,
    toggleModal,
  }
}

export default useModal
