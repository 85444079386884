import { useRef, useCallback, useEffect } from 'react'

const useClickOutside = (handleClickOutside) => {
  const ref = useRef(null)

  const onClickOutside = useCallback(
    (event) => {
      if (handleClickOutside && ref && ref.current && !ref.current.contains(event.target)) {
        event.stopPropagation()
        event.preventDefault()
        handleClickOutside()
      }
    },
    [handleClickOutside]
  )

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true)

    return () => {
      document.removeEventListener('click', onClickOutside, true)
    }
  }, [])

  return {
    ref,
  }
}

export default useClickOutside
