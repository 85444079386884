import React from 'react'

const Footer = ({ divider, children }) => (
  <div className="w-full">
    <div className="py-10">
      <div className="flex justify-end">
        <div>
          {children && children} {divider && divider + ' '}
          <a className="underline" href="https://tgd.global/legal/privacy-policy" alt="Privacy Policy" target="_New">
            Privacy Policy
          </a>
          {' | '}
          <a className="underline" href="https://tgd.global/legal/terms-of-use" alt="Terms Of Use" target="_New">
            Terms Of Use
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
