import { useState, useEffect, useCallback, useRef } from 'react'

const useOptionCheck = (initialSelectedOption, onChange, optQuestionId, handleTouch) => {
  const [valueOptInputField, setValueOptInputField] = useState('')
  const [optQuestionChecked, setOptQuestionCheck] = useState(false)
  const inputRef = useRef()

  useEffect(() => {
    if (initialSelectedOption && initialSelectedOption.name === optQuestionId) {
      setValueOptInputField(initialSelectedOption.value)
      setOptQuestionCheck(true)
    }
  }, [initialSelectedOption, optQuestionId])

  /* Fns to handle opt input */
  const toggleOptInput = () => {
    if (optQuestionChecked) {
      onChange(null)
      handleTouch(true)
    } else {
      onChange({ id: optQuestionId, name: optQuestionId, value: '' })
      handleTouch(false)
      setTimeout(() => inputRef.current.focus(), 50)
    }
    setValueOptInputField('')
    setOptQuestionCheck(!optQuestionChecked)
  }

  const onChangeOptInput = useCallback(
    (event) => {
      const value = event.target.value
      event.preventDefault()
      setValueOptInputField(value)
      onChange({ id: optQuestionId, name: optQuestionId, value })
      handleTouch(true)
    },
    [setValueOptInputField, onChange, optQuestionId, handleTouch]
  )

  return {
    valueOptInputField,
    optQuestionChecked,
    toggleOptInput,
    onChangeOptInput,
    inputRef,
  }
}

export default useOptionCheck
