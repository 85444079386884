import React from 'react'
import Popover from './Popover'

const NewUserModal = ({ isOpen, toggleModal }) => (
  <Popover open={isOpen} onRequestClose={toggleModal} secondaryAction={{ title: 'Next', onClick: toggleModal }}>
    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mt-4 sm:text-left">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Built on worlds best tools</h3>
      <div className="my-5">
        <p className="text-md leading-6 text-gray-600">
          This tool helps you generate some of the most important documents to test and develop your business idea or
          concept. It builds on important tools such as business ecosystem mapping, business and mission model canvases,
          Value Proposition Canvas and more.
        </p>
      </div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Getting the most out of startup tools</h3>
      <div className="my-5">
        <p className="text-md leading-6 text-gray-600">
          Using this tool will help you to understand the fundamental make up of these tools and get the most out of
          them for yourself, your collaborators and people you need to communicate with about your idea.
        </p>
      </div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Helps you collaborate</h3>
      <div className="my-5">
        <p className="text-md leading-6 text-gray-600">
          You should use this tool to make sure you’re efficient at communicating your idea and help you grow your own
          capabilities and develop your idea as fast as possible.
        </p>
      </div>
    </div>
  </Popover>
)

export default NewUserModal
